import * as creators from '../creators/Plus';

import { SETData, GETData } from '../../services/WebServices';

import { Alert } from 'rsuite';

import { getUnixTime } from 'date-fns';

const urlData = 'https://u897vnfmtl.execute-api.us-east-2.amazonaws.com/PDN';

export const setDataGraph = payload => {
    return {
        type: creators.SET_DATA_GRAPH,
        payload
    }
}

export const setLoadingInitData = payload => {
    return {
        type: creators.SET_LOADING_INIT_DATA,
        payload
    }
}

export const setEnableHistory = payload => {
    return {
        type: creators.SET_ENABLE_HISTORY,
        payload
    }
}

export const setDataGraphStreaming = payload => {
    return {
        type: creators.SET_DATA_GRAPH_STREAMING,
        payload
    }
}

export const setDevices = payload => {
    return {
        type: creators.SET_DEVICES,
        payload
    }
}

export const resetData = () => {
    return {
        type: creators.RESET_DATA
    }
}

export const setEnergyQuality = payload => {
    return {
        type: creators.SET_ENERGY_QUALITY,
        payload
    }
}

export const setLoadingEnergyQuality = payload => {
    return {
        type: creators.SET_LOADING_ENERGY_QUALITY,
        payload
    }
}

export const setAlwaysOn = payload => {
    return {
        type: creators.SET_ALWAYS_ON,
        payload
    }
}

export const setLoadingAlwaysOn = payload => {
    return {
        type: creators.SET_LOADING_ALWAYS_ON,
        payload
    }
}

export const setIndicators = payload => {
    return {
        type: creators.SET_PLUS_INDICATORS,
        payload
    }
}

export const setLoadingIndicators = payload => {
    return {
        type: creators.SET_PLUS_LOADING_INDICATORS,
        payload
    }
}

export const setComparatives = payload => {
    return {
        type: creators.SET_COMPARATIVES,
        payload
    }
}

export const setLoadingComparatives = payload => {
    return {
        type: creators.SET_LOADING_COMPARATIVES,
        payload
    }
}

export const setAppliances = payload => {
    return {
        type: creators.SET_APPLIANCES,
        payload
    }
}

export const setTagRecords = payload => {
    return {
        type: creators.SET_TAG_RECORDS,
        payload
    }
}

export const setLastConection = payload => {
    return {
        type: creators.SET_LAST_CONECTION,
        payload
    }
}

export const setLastDay = payload => {
    return {
        type: creators.SET_LAST_DAY,
        payload
    }
}

export const setLastPage = payload => {
    return {
        type: creators.SET_LAST_PAGE,
        payload
    }
}

export const setOpenQualityEnergy = payload => {
    return {
        type: creators.SET_OPEN_QUALITY_ENERGY,
        payload
    }
}


export const setDataGraphAlwaysOn = payload => {
    return {
        type: creators.SET_DATA_GRAPH_ALWAYS_ON,
        payload
    }
}

export const setDataGraphHistoryInit = payload => {
    return {
        type: creators.SET_DATA_GRAPH_HISTORY_INIT,
        payload
    }
}

export const setIntervalGraphAlwaysOn = payload => {
    return {
      type: creators.SET_INTERVAL_GRAPH_ALWAYS_ON,
      payload,
    };
  };
  
  export const setGraphDatesAlwaysOn = payload => {
    return {
        type: creators.SET_GRAPH_DATES_ALWAYS_ON,
        payload
    }
  }
  
  export const setGraphDatesScrollAlwaysOn = payload => {
    return {
        type: creators.SET_GRAPH_DATES_SCROLL_ALWAYS_ON,
        payload
    }
  }

export const setEnableScrollDataAlwaysOn = payload => {
    return {
        type: creators.SET_ENABLE_SCROLL_DATA_ALWAYS_ON,
        payload
    }
}

export const setLoadingAlwaysOnModule = payload => {
    return {
        type: creators.SET_LOADING_ALWAYS_ON_MODULE,
        payload
    }
}

export const setQualityIndicators = payload => {
    return {
        type: creators.SET_QUALITY_INDICATORS,
        payload
    }
}

export const setLoadingQualityIndicators = payload => {
    return {
        type: creators.SET_LOADING_QUALITY_INDICATORS,
        payload
    }
}

export const setAlwaysOnIndicators = payload => {
    return {
        type: creators.SET_ALWAYS_ON_INDICATORS,
        payload
    }
}

export const setLoadingAlwaysOnIndicators = payload => {
    return {
        type: creators.SET_LOADING_ALWAYS_ON_INDICATORS,
        payload
    }
}

export const loadInitData = (page) => {
    return async (dispatch, getState) => {

        dispatch(setLoadingInitData(true));

        const idService = getState().services.activeServicesPlus[0]?.idService;

        const devices = getState().plus.devices;

        let deviceActive = devices.filter(value => value.id_service === idService);
    
        if (deviceActive.length){
            deviceActive = deviceActive[0]
            await SETData('', 'POST', { page, id_device: deviceActive?.id_device, timezone: deviceActive?.timezone, request: "getInitDataV2" }, urlData, true)
                .then(response => {
                    if (response?.body?.length){
                        const payload = {
                            page: page,
                            data: response?.body,
                            init: true,
                            day: 0
                        }
                        const currentDevice = getState().plus.devices?.filter(value => value?.id_service === (getState().services.activeServicesPlus[0]?.idService));
                        if(currentDevice[0]?.id_device === response?.idDevice){
                            dispatch(setDataGraph(payload));
                        }
                        if(response?.nextShardId !== ''){
                            dispatch(loadStreamingData(response?.nextShardId));
                        }
                    }
                    else{
                        dispatch(setLastConection(response?.lastConection));
                        dispatch(setLastDay(response?.lastDay));
                        dispatch(setLastPage(response?.lastPage));
                    }
                    dispatch(setLoadingInitData(false));
                })
                .catch(err => console.error(err))
                .finally(() => dispatch(loadHistoryData({page: page, day: 0, type: 0}, false, "getHistoryDataInit")));
        }
        else{
            dispatch(setLoadingInitData(false));
            dispatch(setLastConection(null));
            dispatch(setLastDay(0));
            dispatch(setLastPage(1));
        }

    }
}


export const loadHistoryData = ({ page, day, type }, loading = false, request = "getHistoryData") => {
    return async (dispatch, getState) => {

        if (loading) dispatch(setLoadingInitData(true));

        dispatch(setEnableHistory(true));

        const idService = getState().services.activeServicesPlus[0]?.idService;

        const devices = getState().plus.devices;

        let deviceActive = devices.filter(value => value?.id_service === idService);

        if (deviceActive.length){
            deviceActive = deviceActive[0];
            await SETData('', 'POST', { page, id_device: deviceActive?.id_device, timezone: deviceActive?.timezone, request: request, day: day }, urlData, true)
                .then(response => {
                    if (response?.body?.length){
                        const payload = {
                            page: page,
                            data: response?.body,
                            init: false,
                            day: day,
                            type: type
                        }
                        const currentDevice = getState().plus.devices?.filter(value => value?.id_service === (getState().services.activeServicesPlus[0]?.idService));
                        if(currentDevice[0]?.id_device === response?.idDevice){
                            if (request === "getHistoryDataInit"){
                                dispatch(setDataGraphHistoryInit(payload));
                            }
                            else{
                                dispatch(setDataGraph(payload));
                            }
                        }
                    }
                    setTimeout(() => dispatch(setEnableHistory(false)), 5000);
                    dispatch(setLoadingInitData(false));
                })
                .catch(err => console.error(err))
        }
        else{
            dispatch(setEnableHistory(false));
            dispatch(setLoadingInitData(false));
        }

    }
}


export const loadStreamingData = (shardId) => {
    return async (dispatch, getState) => {

        let nextShardId = shardId

        const idService = getState().services.activeServicesPlus[0]?.idService;

        const devices = getState().plus.devices;

        let deviceActive = devices.filter(value => value?.id_service === idService);

        if (shardId?.length > 0 && deviceActive?.length) {
            deviceActive = deviceActive[0];
            await SETData('', 'POST', { id_device: deviceActive?.id_device, request: "getStreamingData", shardId: shardId }, urlData, true)
                .then(response => {
                    if (response?.body?.length) {
                        const currentDevice = getState().plus.devices?.filter(value => value?.id_service === (getState().services.activeServicesPlus[0]?.idService));
                        if(currentDevice[0]?.id_device === response?.idDevice){
                            dispatch(setDataGraphStreaming(response?.body));
                        }
                    }
                    nextShardId = response?.nextShardId?.length ? response?.nextShardId : nextShardId;
                })
                .catch(err => console.error(err));
        }
        setTimeout(() => dispatch(loadStreamingData(nextShardId)), 1500);
    }
}

export const loadDevices = () => {
    
    const user = JSON.parse(localStorage.getItem('user'));

    return async (dispatch) => {
        await GETData(`plus/devices/${user.idUser}`, "GET")
        .then((result) => {
            if(result?.body?.length) {
                dispatch(setDevices(result.body));
            }
        })
        .catch(err => console.error(err));
    }
}

export const loadEnergyQuality = () => {
    return async (dispatch, getState) => {

        const idService = getState().services.activeServicesPlus[0]?.idService;
        
        dispatch(setLoadingEnergyQuality(true));

        await GETData(`plus/quality/${idService}`, "GET")
        .then((result) => {
            if(result?.body.length){
                dispatch(setEnergyQuality(result?.body[0]));
            }
            else{
                dispatch(setEnergyQuality(null));
            }
        })
        .catch(err => console.error(err))
        .finally(() => dispatch(setLoadingEnergyQuality(false)));
    }
}


export const loadAlwaysOn = () => {
    return async (dispatch, getState) => {

        const idService = getState().services.activeServicesPlus[0]?.idService;
        
        dispatch(setLoadingAlwaysOn(true));

        await GETData(`plus/alwaysOn/${idService}`, "GET")
        .then((result) => {
            if(result?.body?.length) {
                dispatch(setAlwaysOn(result?.body));
            }
        })
        .catch(err => console.error(err))
        .finally(() => dispatch(setLoadingAlwaysOn(false)));
    }
}


export const loadIndicators = () => {
    return async (dispatch, getState) => {

        const idService = getState().services.activeServicesPlus[0]?.idService;
        
        dispatch(setLoadingIndicators(true));

        await GETData(`plus/indicators/${idService}`, "GET")
        .then((result) => {
            if(result?.body) {
                dispatch(setIndicators(result?.body));
            }
            else { 
                dispatch(setIndicators([]));
            }
        })
        .catch(err => console.error(err))
        .finally(() => dispatch(setLoadingIndicators(false)));
    }
}


export const loadComparatives = () => {
    return async (dispatch, getState) => {

        const user = JSON.parse(localStorage.getItem('user'));

        const services = getState().services.totalServices.filter(value => value?.type?.typeService === 2);
        
        dispatch(setLoadingComparatives(true));

        await GETData(`plus/comparatives/${user.idUser}`, "GET")
        .then((result) => {
            const allValues = result?.body;
            let comparatives = []
            if(result?.body.length){
                allValues.forEach(item => {
                    const service = services.filter(value => value.idService === item.id_service);
                    if (service.length) comparatives.push({...service[0], value: `${item.value} kWh`, state: 2});
                })
                dispatch(setComparatives(comparatives));
            }
            else{
                dispatch(setComparatives([]));
            }
        })
        .catch(err => console.error(err))
        .finally(() => dispatch(setLoadingComparatives(false)));
    }
}


export const loadAppliances = () => {
    
    return async (dispatch, getState) => {

        const idService = getState().services.activeServicesPlus[0]?.idService;

        await GETData(`plus/tags/appliances/${idService}`, "GET")
        .then((result) => {
            dispatch(setAppliances(result.body));
        })
        .catch(err => console.error(err));
    }
}

export const loadTagRecords = (payload) => {
    
    return async (dispatch, getState) => {

        await GETData(`plus/tags/records/${payload}`, "GET")
        .then((result) => {
            if(result?.body?.length){
                const resultModified = result.body.map(item => ({...item, id_tag: parseInt(item.id_tag)}))
                dispatch(setTagRecords(resultModified));
            }
            else{
                dispatch(setTagRecords([]));
            }
        })
        .catch(err => console.error(err));
    }
}

export const addTags = (payload) => {
    
    return async (_dispatch, getState) => {

        const language = getState().shared.language;        

        await SETData(`plus/tags`, "POST", payload)
        .then((result) => {
            if(result !== null){
                Alert.success(language.alert_create_new_tag_success)
            }else {
                Alert.error(language.alert_create_new_tag_failed)
            }
        })
        .catch(err => console.error(err));
    }
}

export const addAppliance = (payload) => {
    
    return async (dispatch, getState) => {

        const language = getState().shared.language; 

        await SETData(`plus/tags/create`, "POST", payload)
        .then((result) => {
            dispatch(loadAppliances());
            if(result !== null){
                Alert.success(language.alert_create_new_device_success, 3000)
            }else {
                Alert.error(language.alert_create_new_device_failed)
            }
        })
        .catch(err => console.error(err));
    }
}

export const loadGraphAlwaysOnAsync = (loadByScroll = false, requestLeft = true) => {
    return async (dispatch, getState) => {
  
      if(!loadByScroll) dispatch(setLoadingAlwaysOnModule(true));
  
      dispatch(setEnableScrollDataAlwaysOn(false));

      let data = []
  
      let from = getUnixTime(loadByScroll ? getState().plus.graphDateFromScrollAlwaysOn : getState().plus.graphDateFromAlwaysOn);
      let to = getUnixTime(loadByScroll ? getState().plus.graphDateToScrollAlwaysOn : getState().plus.graphDateToAlwaysOn);
      const activeServicesPlus = getState().services.activeServicesPlus;
      switch (getState().plus.intervalGraphAlwaysOn) {
        case 1:
          if(loadByScroll) {
            if (requestLeft) {
              to = from;
              from -= 604800 * 1;
            }
            else{
              from = to;
              to += 604800 * 1;
            }
          }
          else{
            from -= 604800 * 1;
          }
          dispatch(setGraphDatesScrollAlwaysOn({graphDateFromAlwaysOn: new Date(from * 1000), graphDateToAlwaysOn: new Date(to * 1000)}));
          break;
        case 2:
          if(loadByScroll) {
            if (requestLeft) {
              to = from;
              from -= 2592000 * 1;
            }
            else{
              from = to;
              to += 2592000 * 1;
            }
          }
          else{
            from -= 2592000 * 1;
          }
          dispatch(setGraphDatesScrollAlwaysOn({graphDateFromAlwaysOn: new Date(from * 1000), graphDateToAlwaysOn: new Date(to * 1000)}));
          break;
        case 3:
          if(loadByScroll) {
            if (requestLeft) {
              to = from;
              from -= 31536000 * 1;
            }
            else{
              from = to;
              to += 31536000 * 1;
            }
          }
          else{
            from -= 31536000 * 1;
          }
          dispatch(setGraphDatesScrollAlwaysOn({graphDateFromAlwaysOn: new Date(from * 1000), graphDateToAlwaysOn: new Date(to * 1000)}));
          break;
        default:
          break;
      }   
        if(activeServicesPlus.length){
            await SETData(`plus/alwaysOn/history/${from}/${to}/${activeServicesPlus[0]?.idService}/${getState().plus.intervalGraphAlwaysOn}`, 'GET')
            .then(energyResponse => {
                if (energyResponse?.body?.length) {  
                    energyResponse = energyResponse?.body;
                    if (loadByScroll){
                    const currentGraphData = getState().plus.dataGraphAlwaysOn;
                    if(requestLeft){
                        data = [...energyResponse, ...currentGraphData];
                    }
                    else{
                        data = [...currentGraphData, ...energyResponse];
                    }
                    }
                    else{               
                    data = [...data, ...energyResponse]
                    }
                    dispatch(setEnableScrollDataAlwaysOn(true));
                }
            })
            .catch(response => console.error(response));
            dispatch(setDataGraphAlwaysOn(data));
            dispatch(setLoadingAlwaysOnModule(false));
        }
      }   
  };


export const loadQualityIndicators = () => {
    return async (dispatch, getState) => {

        dispatch(setLoadingQualityIndicators(true));

        const idService = getState().services.activeServicesPlus[0]?.idService;

        const devices = getState().plus.devices;

        let deviceActive = devices.filter(value => value.id_service === idService);
    
        if (deviceActive.length){
            deviceActive = deviceActive[0]
            await SETData('', 'POST', { id_device: deviceActive?.id_device, timezone: deviceActive?.timezone, request: "getQualityIndicators" }, urlData, true)
                .then(response => {
                    if (response?.body){
                        dispatch(setQualityIndicators(response?.body));
                    }
                    else{
                        dispatch(setQualityIndicators(null));
                    }
                    dispatch(setLoadingQualityIndicators(false));
                })
                .catch(err => console.error(err));
        }
        else{
            dispatch(setLoadingQualityIndicators(false));
        }

    }
}

export const loadAlwaysOnIndicators = () => {
    return async (dispatch, getState) => {

        dispatch(setLoadingAlwaysOnIndicators(true));

        const activeServicesPlus = getState().services.activeServicesPlus;
    
        if (activeServicesPlus.length){
            
            await SETData(`plus/alwaysOn/indicators/${activeServicesPlus[0]?.idService}/es`, 'GET')
                .then(response => {
                    if (response?.body){
                        dispatch(setAlwaysOnIndicators(response?.body));
                    }
                    else{
                        dispatch(setAlwaysOnIndicators([]));
                    }
                    dispatch(setLoadingAlwaysOnIndicators(false));
                })
                .catch(err => console.error(err));
        }
        else{
            dispatch(setLoadingAlwaysOnIndicators(false));
        }

    }
}
