import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import * as plusActions from "../../../../store/actions/Plus";

import { SETData } from "../../../../services/WebServices";

import { Modal } from 'rsuite';
import moment from "moment";

import { Loader } from "rsuite";

import { Calendar as Datepicker } from 'react-calendar';
import { Transition } from 'react-transition-group';

import Input from '../../../shared/utilities/Input';
import Select from '../../../shared/utilities/Select';

import AWS from 'aws-sdk';

import { track } from "@amplitude/analytics-browser";

const downloadBlob = (blob, name) => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(
        new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
        })
    );
    document.body.removeChild(link);
}


const handleDownloadCSV = async (path, name, type, setStepDownload) => {
    AWS.config.update({
        accessKeyId: 'AKIA3MNLSUCN4VGVIRVF',
        secretAccessKey: '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'
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: 'neu-plus',
      Key: path,
    };
    
    await s3.getObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        let csvBlob = new Blob([data.Body.toString()], {
          type: `text/${type};charset=utf-8;`,
        });
        downloadBlob(csvBlob, name);
        setStepDownload(0);
      }
    });

}

const exportFile = async (payload, setStepDownload) => {
    const endpoint = 'https://g5c5659uk1.execute-api.us-east-2.amazonaws.com/PDN/file';
    setStepDownload(1);
    await SETData('', 'POST', payload, endpoint, true)
    .then(async response => {
        if(response.statusCode === 200){
            const path = response.body;
            const name = path.split("/")[2];
            const type = name.split(".")[1];
            setStepDownload(2);
            await handleDownloadCSV(path, name, type, setStepDownload);
        }
    })
    .catch(response => {
        console.error(response);
    });
}


const showError = (payloadExport, setFocus, setError, language) => {

    if (payloadExport.startTime === null) {        
        setFocus(1);
        setError(language.alert_date_report_error);
        return;
    }
    
    if (payloadExport.nameFile === null || payloadExport.nameFile === "") {        
        setFocus(2);
        setError(language.alert_filename_report_error);
        return;
    }

    if (payloadExport.request === null) {        
        setFocus(3);
        setError(language.alert_format_filename_report_error);
        return;
    }    

    return setFocus(0);
}


const validate = (payloadExport) => {

    if (payloadExport.nameFile === null || payloadExport.nameFile === "") {
        return false;
    }

    if (payloadExport.request === null) {
        return false;
    }

    if (payloadExport.startTime === null) {
        return false;
    }

    return true

}



const ExportGraph = (props) => {

    const formatTypes = [
        {
            value: 'CSV',
            label: 'CSV'
        },
        {
            value: 'HTML',
            label: 'HTML (Gráfica interactiva)'
        }
    ];

    const payloadToExportInit = {
        request: "CSV",
        nameFile: null,
        startTime: null,
        endTime: null,
        deviceId: null,
        projectName: ""
    }

    let language = props.language;

    const [payloadToExport, setPayloadToExport] = useState(payloadToExportInit);
    const [stepDownload, setStepDownload] = useState(0);
    const [showCalendar, setShowCalendar] = useState(false);
    const [focus, setFocus] = useState(0);   
    const [error, setError] = useState("");

    useEffect(() => {
        if(props.devices.length){
            const idService = props.activeServicesPlus[0]?.idService;
            const deviceActive = props.devices.filter(value => value?.id_service === idService)[0];
            setPayloadToExport({ ...payloadToExport, deviceId: deviceActive?.id_device, projectName: props.activeServicesPlus[0]?.name });
        }
        // eslint-disable-next-line
    }, [props.devices, props.activeServicesPlus])

    useEffect(() => {
        if(stepDownload === 0){
            props.setShow(false);
        }
        // eslint-disable-next-line
    }, [stepDownload])

    useEffect(() => {
        if(focus !== 0) {showError(payloadToExport, setFocus, setError, language)}                      
    }, [payloadToExport, language, focus]);

    return(
        <Modal
            size="xs" 
            overflow={false}
            backdropClassName={'bg-black opacity-80 pe-pointer'}    
            dialogClassName="rounded"                  
            show={props.show}                                      
            onHide={() => props.setShow(false)}
        >
            <Fragment>
                <div className='column-start w-100 h-100'>
                    <div className='w-100 txt-white txt-phrase txt-12 txt-semibold text-center mt-3'>{props.language.plus_export_graph_title}</div> 
                    <div className='column-start p-3'>
                        <div className='txt-blue-green-gradient txt-phrase txt-8 txt-bold'>{props.language.plus_export_graph_date}</div>
                        <div className="w-100 my-2">
                            <div className={`form-control border-2 border-color-${focus === 1 ? 'red-500' : 'white'} bg-transparent txt-white txt-phrase txt-8 py-3 pe-pointer`} onClick={() => setShowCalendar(!showCalendar) }>
                                <div className="row-between align-items-center">
                                    {payloadToExport.startTime ? payloadToExport.startTime.split(" ")[0] : "Selecciona la fecha"}
                                    <i className="fa-solid fa-calendar-days txt-10"></i>
                                </div>
                            </div>
                            {focus === 1 ? <p className='txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap'>{error}</p> : null}
                            <Transition
                                in={showCalendar}
                                timeout={50}>
                                {state => (
                                    <div className={`trans-fade trans-fade-${state}`}>
                                        <Datepicker 
                                            next2Label={null}
                                            prev2Label={null}
                                            maxDate={new Date()}
                                            className={"bg-gray-500 txt-phrase txt-8 p-2 rounded-8"}
                                            nextLabel={<i className="fa-regular fa-chevron-right txt-8 txt-white pe-pointer"></i>}
                                            prevLabel={<i className="fa-regular fa-chevron-left txt-8 txt-white pe-pointer"></i>}
                                            onChange={value => {
                                                const date = moment(value).format("YYYY-MM-DD");
                                                setPayloadToExport({ ...payloadToExport, startTime: `${date} 00:00:00`, endTime: `${date} 23:59:59` });
                                                setShowCalendar(false);
                                            }}/>
                                    </div>
                                )} 
                            </Transition>
                        </div>
                        <div className='txt-blue-green-gradient txt-phrase txt-8 txt-bold mt-3'>{props.language.plus_export_graph_filename}</div>
                        <div className="w-100 mt-2 mb-4">
                            <Input 
                                label={props.language.export_graph_example_filename} 
                                type={'text'} 
                                onChange={({target}) => setPayloadToExport({ ...payloadToExport, nameFile: target.value })}
                                error={focus === 2 ? error : ""}
                            />
                        </div>
                        <div className='txt-blue-green-gradient txt-phrase txt-8 txt-bold'>{props.language.plus_export_graph_format}</div>
                        <div className="w-100 my-2">
                            <Select 
                                label={"Elige el formato del archivo"} 
                                background={'gray-500'} 
                                options={formatTypes} 
                                onChange={({target}) => setPayloadToExport({ ...payloadToExport, request : target.value })}
                                error={focus === 3 ? error : ""}
                            />
                        </div>
                        <div className={`${stepDownload === 0 ? null : "my-3"} txt-8 txt-phrase txt-bold ${stepDownload === 1 ? "txt-blue-700" : "txt-green-500"}`}>
                        {stepDownload === 1 ? 
                            <div className="row-start align-items-center">
                                <i className="fa-regular fa-circle-info txt-10"></i>
                                <div className="txt-phrase txt-semibold txt-8 ms-2 pe-pointer">{`El reporte ${payloadToExport.nameFile} se está creando.`}</div>
                            </div>
                        : 
                        stepDownload === 2 ? 
                            <div className="row-start align-items-center">
                                <i className="fa-regular fa-circle-check txt-10"></i>
                                <div className="txt-phrase txt-semibold txt-8 ms-2 pe-pointer">{`El reporte ${payloadToExport.nameFile} se está descargando.`}</div>
                            </div>
                        : null}
                        </div>
                        <div className='row-end my-2'>
                            <button className='me-3 bg-green-500 rounded-8 py-2 px-4 txt-blue-900 txt-phrase txt-8 txt-bold' 
                                onClick={async() => {                                    
                                    if(validate(payloadToExport) && stepDownload === 0){
                                        await exportFile(payloadToExport, setStepDownload);                                        
                                        track('Plus graphic downloaded', {Rank_ID: payloadToExport?.startTime})
                                    }
                                    else{
                                        showError(payloadToExport, setFocus, setError, language)
                                    }
                                }}>
                                {stepDownload === 0 ?
                                <div className="row-start align-items-center">
                                    <i className="fa-regular fa-file-arrow-down txt-10"></i>
                                    <div className="txt-phrase txt-semibold txt-8 ms-2 pe-pointer">{props.language.plus_export_graph_button}</div>
                                </div> : <div className='column-center align-items-center h-100'><Loader inverse /></div>}
                            </button>
                            <button className='bg-gray-500 rounded-8 py-2 px-4 border-2 border-color-red-500 txt-red-500 txt-phrase txt-8 txt-bold' onClick={() => {props.setShow(false); setPayloadToExport(payloadToExportInit); setStepDownload(0)}}>{props.language.tag_new_device_button_cancel}</button>
                        </div>                       
                    </div>                  
                </div>
            </Fragment>           
        </Modal>

    );
}

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    devices: state.plus.devices,
    language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
    addAppliance: (payload) => dispatch(plusActions.addAppliance(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportGraph);