import * as creators from '../creators/Shared';

const initialState = {
    language: {},
    currentModule: 0,
    showMenu: false,
    showBlackScreen: false,
    showBlackScreenDrawer: false,
    showCalendar: false,
    showNpsSurvey: false,
    showDrawerOnboarding: false,
    startOnboarding: {
        dashboard: false,
        service_detail: false,
        service_picker: false,
        graph_type_picker: false,
        graph_period_picker: false,
        graph_calendar_picker: false,
        graph_export_data: false,
        new_alert: false,
        new_user: false,
        payment: false,
        invoice: false,
        profile: false,
        walktrough: false
    },
    controlledOnboarding: {
        service_picker: false,
        graph_type_picker: false,
        graph_period_picker: false,
        graph_calendar_picker: false,
        graph_export_data: false,
        new_alert: false
    },
    showPulseAnimation: false
};

export const sharedReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_LANGUAGE:
            return { ...state, language: payload };
        case creators.SET_MENU:
            return { ...state, showMenu: payload };
        case creators.SET_SHOW_BLACK_SCREEN:
            return { ...state, showBlackScreen: payload };
        case creators.SET_SHOW_CALENDAR:
            return { ...state, showCalendar: payload };
        case creators.SET_SHOW_BLACK_SCREEN_DRAWER:
            return { ...state, showBlackScreenDrawer: payload };
        case creators.SET_SHOW_NPS_SURVEY:
            return { ...state, showNpsSurvey: payload };
        case creators.SET_CURRENT_MODULE:
            return { ...state, currentModule: payload };
        case creators.SET_START_ONBOARDING:

            const currentStartOnboarding = { ...state.startOnboarding };
            currentStartOnboarding[payload.key] = payload.value;

            return { ...state, startOnboarding: currentStartOnboarding };
        case creators.SET_CONTROLLED_ONBOARDING:

            const currentControlledOnboarding = { ...state.controlledOnboarding };
            currentControlledOnboarding[payload.key] = payload.value;

            return { ...state, controlledOnboarding: currentControlledOnboarding };
        case creators.SET_SHOW_DRAWER_ONBOARDING:
            return { ...state, showDrawerOnboarding: payload };
        case creators.SET_SHOW_PULSE_ANIMATION:
            return { ...state, showPulseAnimation: payload };
        default:
            return state;
    }
}