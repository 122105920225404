import React from 'react'

const ButtonCircle = ({ focus, icon, onClick, background = 'gray-450', borderColor = 'gray-800', color = 'gray-700', borderWidth = 1, size = 32, sizeIcon = 12, additionalStyle = "", showPulse = false }) => {

    return (
        <button className={`position-relative rounded-circle ${showPulse && 'pulse'} ${focus ? "zindex-8" : ""} text-${color} bg-${background} border-${borderWidth} border-color-${borderColor} ${additionalStyle}`} style={{ height: size, width: size }} onClick={onClick}>
            <i className={`button-circle-icon position-absolute fa-light fa-${icon} txt-${sizeIcon} txt-${color}`}></i>
        </button>
    )
}


export default ButtonCircle