import AWS from 'aws-sdk';

const current = process.env.REACT_APP_API_URL;

const SETData = async (url, method, params, endpoint = current, simple = false) => {

    let token = localStorage.getItem('token');

    const headers_simple = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: simple ? headers_simple :
            new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${token}`
            }),
        body: JSON.stringify(params)
    });

    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }

    } else {
        return null;
    }

}

const SETFile = async (url, method, params, endpoint = current) => {

    let token = localStorage.getItem('token');

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: params
    });

    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }

    } else {
        return null;
    }

}

const GETFile = async (url, method, params, endpoint = current) => {

    let token = localStorage.getItem('token');

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(params)
    });

    if (response.ok) {
        return response;
    } else {
        return null;
    }

}

const GETData = async (url, method, endpoint = current, simple = false) => {

    let token = localStorage.getItem('token');

    const headers_simple = {
        'Accept': 'application/json'
    };

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        mode: "cors",
        headers: simple ? headers_simple :
            new Headers({
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${token}`
            })
    });
    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }

    } else { return null; }

};

const GETS3PublicUrl = (path, bucket = process.env.REACT_APP_S3_BUCKET, region = process.env.REACT_APP_S3_REGION) => {

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_AWS,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_AWS,
        signatureVersion: 'v4',
        region: region
    });

    const s3 = new AWS.S3();

    const url = s3.getSignedUrl('getObject', {
        Bucket: bucket,
        Key: path,
        Expires: 60
    });

    return url;
}

export { SETData, SETFile, GETData, GETFile, GETS3PublicUrl };