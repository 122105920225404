import React, { useRef, useEffect, useState, Fragment } from "react";
import { Transition } from 'react-transition-group';
import { Toggle } from 'rsuite';
import { Loader } from 'rsuite';

import { connect } from "react-redux";

import * as plus from "../../../../store/actions/Plus";
import * as servicesActions from '../../../../store/actions/Services';

import { VictoryChart, VictoryGroup, VictoryAxis, VictoryLine, VictoryArea, createContainer, VictoryLabel, Rect } from "victory";

import { useWindowSize } from "../../../shared/utilities/useWindowSize";

import { gradients, grid, tickLabelsY, tickStyle } from "../../../shared/svgs/GraphStyles";
import empty_data_device from "../../../../imgs/empty-data-device.svg";

import { track } from "@amplitude/analytics-browser";

import moment from "moment";

import 'moment/locale/es'

import _ from 'lodash';

const BouncingDotsLoader = (props) => {
    return (
        <div className="bouncing-loader">
            {[0, 1, 2].map((_item, index) => (<div key={index} />))}
        </div>
    );
};


const getInitPage = (timezone) => {
    const initPageByTime = new Date().toLocaleString("en-US", { timeZone: timezone });
    return new Date(initPageByTime).getHours();
}


const areaStyle = (variable, line = false) => {
    switch (variable) {
        case 'active_power_':
            return (
                [
                    {
                        data: {
                            fill: line ? "none" : "url(#linearBlueLightTransparent25)",
                            stroke: "#99f1ff",
                            strokeWidth: 1
                        }
                    },
                    {
                        data: {
                            fill: line ? "none" : "url(#linearBlueTransparent25)",
                            stroke: "#00B7D3",
                            strokeWidth: 1,
                            fillOpacity: 0.6
                        }
                    },
                    {
                        data: {
                            fill: line ? "none" : "url(#linearBlueDarkTransparent25)",
                            stroke: "#6199A3",
                            strokeWidth: 1,
                            fillOpacity: 0.6
                        }
                    }
                ]);
        case 'rms_voltage_':
            return (
                [
                    {
                        data: {
                            fill: line ? "none" : "url(#linearGreenLightTransparent25)",
                            stroke: "#e6fe9a",
                            strokeWidth: 1,
                        }
                    },
                    {
                        data: {
                            fill: line ? "none" : "url(#linearGreenTransparent25)",
                            stroke: "#CEFC34",
                            strokeWidth: 1,
                            fillOpacity: 0.6
                        }
                    },
                    {
                        data: {
                            fill: line ? "none" : "url(#linearGreenDarkTransparent75)",
                            stroke: "#5E741A",
                            strokeWidth: 1,
                            fillOpacity: 0.6
                        }
                    },
                ]);
        case 'rms_current_':
            return (
                [
                    {
                        data: {
                            fill: line ? "none" : "url(#linearPurpleLightTransparent25)",
                            stroke: "#daa1f7",
                            strokeWidth: 1,
                        }
                    },
                    {
                        data: {
                            fill: line ? "none" : "url(#linearPurpleTransparent25)",
                            stroke: "#9F14E5",
                            strokeWidth: 1,
                            fillOpacity: 0.6
                        }
                    },
                    {
                        data: {
                            fill: line ? "none" : "url(#linearPurpleDarkTransparent25)",
                            stroke: "#754c8a",
                            strokeWidth: 1,
                            fillOpacity: 0.6
                        }
                    },
                ]);
        default:
            return []

    }
}

const variableName = graph => {
    switch (graph) {
        case 'active_power_':
            return "Potencia (Watts)";
        case 'rms_voltage_':
            return "Voltaje (Volts)";
        case 'rms_current_':
            return "Corriente (Amps)";
        default:
            return "";
    }
}

const LoadingHistoryData = () =>
    <div className="position-absolute left-50 top-0 mt-0 zindex-1">
        <BouncingDotsLoader />
    </div>;

const graphLoadingContainer = (loading, language) => <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1 p-4">
    <Transition
        in={loading}
        timeout={100}>
        {state => (
            <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
                <div className="column-center align-items-center w-100 h-100">
                    <Loader inverse center size="md" content={language.loader_text_content} />
                </div>
            </div>
        )}
    </Transition>
</div>;

const VictoryZoomContainer = createContainer('zoom', 'voronoi');

const Graphs = (props) => {

    const graphContainer = useRef(null);

    const size = useWindowSize();

    const [graphContainerWidth, setGraphContainerWidth] = useState(0);
    const [graph_1, setGraph_1] = useState('active_power_');
    const [graph_2, setGraph_2] = useState('rms_voltage_');
    const [graph_3, setGraph_3] = useState('rms_current_');

    const [firstLine, setFirstLine] = useState(1);
    const [secondLine, setSecondLine] = useState(2);
    const [thirdLine, setThirdLine] = useState(3);

    const [typeLines, setTypeLines] = useState(false);

    const [day, setDay] = useState(0);

    const [data, setData] = useState([]);

    const [domain, setDomain] = useState(null);
    const [entireDomain, setEntireDomain] = useState(null);
    const [showStreaming, setShowStreaming] = useState(true);
    const [zoomMinutes, setZoomMinutes] = useState(3);
    const [grabbing, setGrabbing] = useState(false);
    const [pointTooltip, setPointTooltip] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [enableLoaderGraph, setEnableLoaderGraph] = useState(false);
    const [indexVariable, setIndexVariable] = useState(0);
    const [expandedVariable, setExpandedVariable] = useState(false);
    const [expandedLine, setExpandedLine] = useState(false);

    let dataGraph = props.dataGraph;

    let deviceActive = props.devices.filter(value => value?.id_service === props.activeServicesPlus[0]?.idService)[0];

    useEffect(() => {
        if (expandedVariable) {
            if (graph_1 === 'active_power_') track('Quality variable clicked', { Quality_Variable_ID: 'active_power' });
            if (graph_1 === 'rms_voltage_') track('Quality variable clicked', { Quality_Variable_ID: 'rms_voltage' });
            if (graph_1 === 'rms_current_') track('Quality variable clicked', { Quality_Variable_ID: 'rms_current' });
            setExpandedVariable(false);
        }
    }, [graph_1, expandedVariable])

    useEffect(() => {
        if (expandedLine) {
            if (firstLine === 1) track('Quality variable clicked', { Quality_Variable_ID: 'line_1' });
            if (firstLine === 2) track('Quality variable clicked', { Quality_Variable_ID: 'line_2' });
            if (firstLine === 3) track('Quality variable clicked', { Quality_Variable_ID: 'line_3' });
            setExpandedLine(false);
        }
    }, [firstLine, expandedLine])

    useEffect(() => { setGraphContainerWidth(graphContainer.current.getBoundingClientRect().width) }, [size]);

    useEffect(() => {
        if (!props.devices.length) {
            props.loadDevices()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.devices.length && props.activeServicesPlus.length && !props.loadingData) {
            setZoomMinutes(3);
            props.loadInitData(getInitPage(deviceActive?.timezone) + 1);
            setDomain(null);
            setShowStreaming(true);
            setEnableLoaderGraph(false);
            setIndexVariable(0);
            setFirstLine(1);
            setSecondLine(2);
            setThirdLine(3);
            setPointTooltip(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.devices, props.activeServicesPlus]);

    useEffect(() => {
        if (dataGraph?.length > 0) {
            const intervalX = [dataGraph[0].time, _.last(dataGraph).time];
            setEntireDomain({ x: intervalX });
            if (!domain) {
                setDomain({ x: [intervalX[1] - zoomMinutes * 60 * 1000 < intervalX[0] ? intervalX[0] : intervalX[1] - zoomMinutes * 60 * 1000, intervalX[1]] });
            }
            if (showStreaming && data.length) {
                setDomain({ x: [intervalX[1] - zoomMinutes * 60 * 1000 < intervalX[0] ? intervalX[0] : intervalX[1] - (zoomMinutes) * 60 * 1000, intervalX[1]] });
            }
        }
        else {
            setDomain(null);
            setShowStreaming(true);
            setData([]);
            setZoomMinutes(3);
            setIndexVariable(0);
            setFirstLine(1);
            setSecondLine(2);
            setThirdLine(3);
            setPointTooltip(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGraph, showStreaming]);

    useEffect(() => {
        if (domain) {
            let filtered = dataGraph.filter(item => item.time >= domain.x[0] - 30000 && item?.time <= domain.x[1] + 30000);
            if (filtered.length > 240) {
                const k = Math.ceil(filtered.length / 240);
                filtered = filtered.filter((_d, i) => ((i % k) === 0));
            }
            setData(filtered);
            const dataGraphNoInit = props.dataGraphPage.filter(item => !item?.init);
            const firstAvailablePage = dataGraphNoInit.length ? dataGraphNoInit[0]?.page : getInitPage(deviceActive?.timezone);
            const intervalLeft = (domain.x[0] - entireDomain.x[0]) / 60000;
            const verifyPageLeft = props.dataGraphPage.filter(item => item?.page === firstAvailablePage - 1);
            if (intervalLeft <= 60 && !props.enableHistory && !verifyPageLeft.length) {
                props.loadHistoryData(handleBeforeDay(firstAvailablePage - 1), false);
            }
            if (props.dataGraphPage.length >= 3) {
                const lastAvailablePage = _.last(dataGraphNoInit)?.page;
                const lastValueX = _.last(props.dataGraphPage[props.dataGraphPage.length - 2]?.data)?.time;
                const intervalRight = (lastValueX - domain.x[1]) / 60000;
                const verifyPage = props.dataGraphPage.filter(item => item?.page === lastAvailablePage + 1);
                if (intervalRight <= 60 && !verifyPage.length && !props.enableHistory && lastAvailablePage + 1 !== _.last(props.dataGraphPage).page - 1) {
                    props.loadHistoryData(handleAfterDay(lastAvailablePage + 1), false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain, props.activeServicesPlus]);

    const handleBeforeDay = page => {
        if (page > 0) return { page: page, day: day, type: 0 }
        else {
            setDay(day + 1);
            return { page: 24, day: day + 1, type: 0 };
        }
    }

    const handleAfterDay = page => {
        if (page < 25) return { page: page, day: day, type: 1 }
        else {
            setDay(day - 1);
            return { page: 1, day: day - 1, type: 1 };
        }
    }

    const formatNumber = number => {
        if (number >= 1000) {
            number = (number / 1000).toFixed(2);
            return (`${number} k`)
        }
        else {
            return (`${(number).toFixed(2)} `);
        }
    }

    const handlerIndexVariable = (variable) => {
        if (variable === 'active_power_') setIndexVariable(0);
        if (variable === 'rms_voltage_') setIndexVariable(1);
        if (variable === 'rms_current_') setIndexVariable(2);
    }

    const handlerVariable = (variable) => {
        if (graph_2 === variable) {
            setGraph_2(graph_1);
            setGraph_1(variable);
            handlerIndexVariable(variable);
        }
        if (graph_3 === variable) {
            setGraph_3(graph_1);
            setGraph_1(variable);
            handlerIndexVariable(variable);
        }
    }

    const Tooltip = ({ scale }) => {
        let x = pointTooltip ? scale.x(pointTooltip?.time) : 150;
        let y_1 = pointTooltip ? scale.y(pointTooltip[`${graph_1}${typeLines ? firstLine : 1}`]) : 50;
        let y_2 = pointTooltip ? scale.y(pointTooltip[`${graph_1}${typeLines ? firstLine : 2}`]) : 50;
        let y_3 = pointTooltip && deviceActive?.phases === 3 ? scale.y(pointTooltip[`${graph_1}${typeLines ? firstLine : 3}`]) : 50;
        let y_value = pointTooltip ? pointTooltip._y : 0;
        let y_value_1 = pointTooltip ? pointTooltip[`${graph_1}1`] : 0;
        let y_value_2 = pointTooltip ? pointTooltip[`${graph_1}2`] : 0;
        let y_value_3 = pointTooltip && deviceActive?.phases === 3 ? pointTooltip[`${graph_1}3`] : 0;
        let x_value = pointTooltip ? moment.utc(pointTooltip?.time).format('HH:mm:ss') : '00:00';
        const unit = graph_1 === 'active_power_' ? 'W' : graph_1 === 'rms_voltage_' ? 'V' : 'A';
        return (
            <Fragment>
                {pointTooltip ?
                    <g>
                        <line
                            x1={x}
                            x2={x}
                            y1={0}
                            y2={345}
                            stroke="#f9fafb"
                            strokeWidth="2"
                            strokeDasharray={10}
                        />
                        <rect
                            x={x - 60}
                            y={0}
                            width={120}
                            height={typeLines ? 46 : deviceActive?.phases === 3 ? 84 : 70}
                            rx={8}
                            ry={8}
                            fill="#323234"
                        />
                        <circle cx={x} cy={y_1} r="5" stroke="white" strokeWidth="2" fill={areaStyle(graph_1)[0]?.data?.stroke} />
                        {!typeLines ?
                            <Fragment>
                                <circle cx={x} cy={y_2} r="5" stroke="white" strokeWidth="2" fill={areaStyle(graph_1)[1]?.data?.stroke} />
                                {deviceActive?.phases === 3 ? <circle cx={x} cy={y_3} r="5" stroke="white" strokeWidth="2" fill={areaStyle(graph_1)[2]?.data?.stroke} /> : null}
                            </Fragment> : null
                        }
                        <polygon points={`${x - 20},${typeLines ? 33 : deviceActive?.phases === 3 ? 81 : 63} ${x + 20},${typeLines ? 33 : deviceActive?.phases === 3 ? 81 : 63} ${x},${typeLines ? 53 : deviceActive?.phases === 3 ? 91 : 83}`} fill="#323234" />
                        {typeLines ?
                            <Fragment>
                                <foreignObject x={x - 40} y={15} width="30" height="30">
                                    <i className="fa-solid fa-circle-location-arrow txt-12 txt-gray-50" />
                                </foreignObject>
                                <text x={x - 15} y={20} fontSize={12} fill={'white'} fontFamily={'Inter'}>{x_value}</text>
                            </Fragment> : null}
                        {typeLines ? <text x={x - 15} y={35} fontSize={12} fill={areaStyle(graph_1)[0]?.data?.stroke} fontFamily={'Inter'} fontWeight={'bold'}>{`${formatNumber(y_value)}${unit}`}</text> :
                            <g>
                                <text x={x} y={20} textAnchor="middle" alignmentBaseline="middle" fontSize={10} fill={'white'} fontFamily={'Inter'}>{x_value}</text>
                                <text x={x} y={38} textAnchor="middle" alignmentBaseline="middle" fontSize={10} fontFamily={'Inter'} fontWeight={'normal'}><tspan fill={'white'}>Línea 1: </tspan><tspan fill={areaStyle(graph_1)[0]?.data?.stroke}>{`${formatNumber(y_value_1)}${unit}`}</tspan></text>
                                <text x={x} y={56} textAnchor="middle" alignmentBaseline="middle" fontSize={10} fontFamily={'Inter'} fontWeight={'normal'}><tspan fill={'white'}>Línea 2: </tspan><tspan fill={areaStyle(graph_1)[1]?.data?.stroke}>{`${formatNumber(y_value_2)}${unit}`}</tspan></text>
                                {deviceActive?.phases === 3 ? <text x={x} y={74} textAnchor="middle" alignmentBaseline="middle" fontSize={10} fontFamily={'Inter'} fontWeight={'normal'}><tspan fill={'white'}>Línea 3: </tspan><tspan fill={areaStyle(graph_1)[2]?.data?.stroke}>{`${formatNumber(y_value_3)}${unit}`}</tspan></text> : null}
                            </g>}
                    </g> : null}
            </Fragment>
        );
    }

    return (
        <div className="position-relative column-start align-items-center w-100">
            {gradients}
            <div className="column-between align-center h-100 w-100">
                <div className="row-between align-items-center mb-1" style={{ height: 40 }}>
                    <div className="row-start align-items-center">
                        <p className="txt-phrase txt-medium txt-8 txt-gray-700">{props.language.quality_graph_lines}</p>
                        <Toggle className="mx-3" checked={!typeLines} onChange={() => { setTypeLines(!typeLines); }} />
                        <p className="txt-phrase txt-medium txt-8 txt-gray-700">{props.language.quality_graph_variables}</p>
                    </div>
                    {
                        typeLines && dataGraph.length &&
                        <div className="position-relative row-start align-items-center py-2 rounded-pill" style={{ width: 400, border: '4px solid #1D1D1D', backgroundColor: '#1D1D1D' }}>
                            <div className="row-center align-items-center h-100 col-4 zindex-2 pe-pointer" onClick={() => { setIndexVariable(0); handlerVariable('active_power_'); track('Quality variable selected', { Quality_Variable_ID: 'active_power' }); }}>
                                <div className="txt-white txt-phrase txt-6 txt-regular pe-pointer">{props.language.quality_graph_power}</div>
                            </div>
                            <div className="row-center align-items-center h-100 col-4 zindex-2 pe-pointer" onClick={() => { setIndexVariable(1); handlerVariable('rms_voltage_'); track('Quality variable selected', { Quality_Variable_ID: 'rms_voltage' }); }}>
                                <div className="txt-white txt-phrase txt-6 txt-regular pe-pointer">{props.language.quality_graph_voltage}</div>
                            </div>
                            <div className="row-center align-items-center h-100 col-4 zindex-2 pe-pointer" onClick={() => { setIndexVariable(2); handlerVariable('rms_current_'); track('Quality variable selected', { Quality_Variable_ID: 'rms_current' }); }}>
                                <div className="txt-white txt-phrase txt-6 txt-regular pe-pointer">{props.language.quality_graph_electricity}</div>
                            </div>
                            <div id="home-graph-picker" className="position-absolute h-100 top-0 col-4 bg-gray-450 border-1 border-color-gray-800 rounded-pill zindex-1" style={{ left: `${(indexVariable) * (100 / 3)}%` }}></div>
                        </div>
                    }
                </div>
                <div ref={graphContainer} className={`row-start align-items-center w-100`} style={{ overflowX: "hidden", overflowY: "hidden" }}>
                    <div style={{ width: graphContainerWidth }}>
                        {
                            props.loadingData ?
                                <div className="position-relative w-100" style={{ minHeight: 580 }}>{graphLoadingContainer(props.loadingData, props.language)}</div> :
                                data.length ?
                                    <Fragment>
                                        <p className="txt-phrase txt-medium txt-white">{typeLines ? `${variableName(graph_1)}-Línea ${firstLine}` : `${variableName(graph_1)}`}</p>
                                        <div className={`position-relative my-2 row-center align-items-center w-100 ${grabbing ? "pe-grabbing" : "pe-grab"}`} onMouseDown={() => setGrabbing(true)} onMouseUp={() => setGrabbing(false)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} >
                                            <VictoryChart
                                                height={370}
                                                width={graphContainerWidth}
                                                domain={{ x: entireDomain?.x }}
                                                domainPadding={{ y: [0, 90] }}
                                                padding={{ top: 10, bottom: 15, left: 0, right: 0 }}
                                                containerComponent={
                                                    <VictoryZoomContainer
                                                        allowDrag={false}
                                                        allowDraw={false}
                                                        allowResize={false}
                                                        allowZoom={false}
                                                        zoomDimension={"x"}
                                                        zoomDomain={{ x: domain?.x }}
                                                        onZoomDomainChange={(domainZoom) => {
                                                            setDomain({ x: domainZoom.x });
                                                            if (domain.x[1] === entireDomain.x[1]) setShowStreaming(true);
                                                            else setShowStreaming(false);
                                                            setEnableLoaderGraph(true);
                                                        }}
                                                        activateData={false}
                                                        onActivated={(point => setPointTooltip(point[0]))}
                                                    />}
                                            >
                                                {typeLines ?
                                                    <VictoryGroup >
                                                        <VictoryArea
                                                            x={'time'}
                                                            y={`${graph_1}${firstLine}`}
                                                            interpolation={'monotoneX'}
                                                            data={data}
                                                            style={areaStyle(graph_1)[1]}
                                                        />
                                                    </VictoryGroup> :
                                                    <VictoryGroup >
                                                        <VictoryArea
                                                            x={'time'}
                                                            y={`${graph_1}1`}
                                                            interpolation={'monotoneX'}
                                                            data={data}
                                                            style={areaStyle(graph_1)[0]}
                                                        />
                                                        <VictoryArea
                                                            x={'time'}
                                                            y={`${graph_1}2`}
                                                            interpolation={'monotoneX'}
                                                            data={data}
                                                            style={areaStyle(graph_1)[1]}
                                                        />
                                                        {deviceActive?.phases === 2 ? null :
                                                            <VictoryArea
                                                                x={'time'}
                                                                y={`${graph_1}3`}
                                                                interpolation={'monotoneX'}
                                                                data={data}
                                                                style={areaStyle(graph_1)[2]}
                                                            />
                                                        }
                                                    </VictoryGroup>
                                                }
                                                <VictoryAxis dependentAxis tickLabelComponent={<VictoryLabel dx={40} dy={-10} textAnchor={'middle'} verticalAnchor={'middle'} backgroundPadding={10} backgroundStyle={tickStyle} backgroundComponent={<Rect rx={8} ry={8}></Rect>}></VictoryLabel>} style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY }} />
                                                {showTooltip ? <Tooltip /> : null}
                                            </VictoryChart>
                                            {(props.enableHistory && domain.x[0] === entireDomain.x[0] && enableLoaderGraph) ? <LoadingHistoryData /> : null}
                                        </div>
                                        <div className="row-between align-items-center">
                                            <p className="txt-phrase txt-medium txt-white">{typeLines ? `${variableName(graph_1)}-Línea ${secondLine}` : `${variableName(graph_2)}`}</p>
                                        </div>
                                        <div className={`position-relative row-center align-items-center w-100 ${grabbing ? "pe-grabbing" : "pe-grab"}`} onMouseDown={() => setGrabbing(true)} onMouseUp={() => setGrabbing(false)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                            <div style={{ width: graphContainerWidth }}>
                                                <VictoryChart
                                                    height={145}
                                                    width={graphContainerWidth}
                                                    style={{ background: { fill: graph_2 === 'rms_voltage_' ? '#CEFC34' : graph_2 === 'active_power_' ? '#00B7D3' : '#b643ef', opacity: 0.04 } }}
                                                    domain={{ x: entireDomain?.x }}
                                                    padding={{ top: 10, bottom: 15, left: 0, right: 0 }}
                                                    domainPadding={{ y: [20, 20] }}
                                                    containerComponent={
                                                        <VictoryZoomContainer
                                                            allowDrag={false}
                                                            allowDraw={false}
                                                            allowResize={false}
                                                            allowZoom={false}
                                                            zoomDimension={"x"}
                                                            zoomDomain={{ x: domain?.x }}
                                                            activateData={false}
                                                            onZoomDomainChange={(domainZoom) => {
                                                                setDomain({ x: domainZoom.x });
                                                                if (domain.x[1] === entireDomain.x[1]) setShowStreaming(true);
                                                                else setShowStreaming(false);
                                                                setEnableLoaderGraph(true);
                                                            }}
                                                        //onActivated={enableTag? null : (point => setPointTooltip(point[0]))}                                                                                           
                                                        />}
                                                >
                                                    {typeLines ?
                                                        <VictoryGroup>
                                                            <VictoryLine
                                                                interpolation={'monotoneX'}
                                                                x={'time'}
                                                                y={`${graph_1}${secondLine}`}
                                                                data={data}
                                                                style={areaStyle(graph_2, true)[1]}
                                                            />
                                                        </VictoryGroup> :
                                                        <VictoryGroup>
                                                            <VictoryLine
                                                                interpolation={'monotoneX'}
                                                                x={'time'}
                                                                y={`${graph_2}1`}
                                                                data={data}
                                                                style={areaStyle(graph_2, true)[0]}
                                                            />
                                                            <VictoryLine
                                                                interpolation={'monotoneX'}
                                                                x={'time'}
                                                                y={`${graph_2}2`}
                                                                data={data}
                                                                style={areaStyle(graph_2, true)[1]}
                                                            />
                                                            {deviceActive?.phases === 2 ? null :
                                                                <VictoryLine
                                                                    interpolation={'monotoneX'}
                                                                    x={'time'}
                                                                    y={`${graph_2}3`}
                                                                    data={data}
                                                                    style={areaStyle(graph_2, true)[2]}
                                                                />}
                                                        </VictoryGroup>}
                                                    <VictoryAxis
                                                        dependentAxis
                                                        tickFormat={(t) => `${t}`}
                                                        tickLabelComponent={
                                                            <VictoryLabel dx={40} dy={-10}
                                                                textAnchor={'middle'} verticalAnchor={'middle'}
                                                                backgroundPadding={10} backgroundStyle={tickStyle}
                                                                backgroundComponent={<Rect rx={8} ry={8}></Rect>}>
                                                            </VictoryLabel>}
                                                        style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY }} />
                                                </VictoryChart>
                                                <div className="position-absolute right-0 top-0 mt-4 me-2 zindex-1">
                                                    <button className="row-center bg-gray-800 rounded-8 p-2"
                                                        onClick={() => {
                                                            if (!typeLines) {
                                                                setGraph_1(graph_2);
                                                                handlerIndexVariable(graph_2)
                                                                setGraph_2(graph_1);
                                                                setExpandedVariable(true);
                                                            } else {
                                                                setSecondLine(firstLine);
                                                                setFirstLine(secondLine);
                                                                setExpandedLine(true);
                                                            }
                                                        }}
                                                    >
                                                        <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center txt-white txt-10" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {deviceActive?.phases === 2 && typeLines ? null :
                                            <Fragment>
                                                <div className="row-between align-items-center">
                                                    <p className="txt-phrase txt-medium txt-white">{typeLines ? `${variableName(graph_1)}-Línea ${thirdLine}` : `${variableName(graph_3)}`}</p>
                                                </div>
                                                <div className={`position-relative row-center align-items-center w-100 ${grabbing ? "pe-grabbing" : "pe-grab"}`} onMouseDown={() => setGrabbing(true)} onMouseUp={() => setGrabbing(false)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                                    <div style={{ width: graphContainerWidth }}>
                                                        <VictoryChart
                                                            height={145}
                                                            width={graphContainerWidth}
                                                            style={{ background: { fill: graph_3 === 'rms_voltage_' ? '#CEFC34' : graph_3 === 'active_power_' ? '#00B7D3' : '#b643ef', opacity: 0.04 } }}
                                                            domain={{ x: entireDomain?.x }}
                                                            padding={{ top: 10, bottom: 15, left: 0, right: 0 }}
                                                            domainPadding={{ y: [20, 20] }}
                                                            containerComponent={
                                                                <VictoryZoomContainer
                                                                    allowDrag={false}
                                                                    allowDraw={false}
                                                                    allowResize={false}
                                                                    allowZoom={false}
                                                                    zoomDimension={"x"}
                                                                    zoomDomain={{ x: domain?.x }}
                                                                    activateData={false}
                                                                    onZoomDomainChange={(domainZoom) => {
                                                                        setDomain({ x: domainZoom.x });
                                                                        if (domain.x[1] === entireDomain.x[1]) setShowStreaming(true);
                                                                        else setShowStreaming(false);
                                                                        setEnableLoaderGraph(true);
                                                                    }}
                                                                //onActivated={enableTag? null : (point => setPointTooltip(point[0]))}                                                                                           
                                                                />}
                                                        >
                                                            {typeLines ?
                                                                <VictoryGroup>
                                                                    <VictoryLine
                                                                        interpolation={'monotoneX'}
                                                                        x={'time'}
                                                                        y={`${graph_1}${thirdLine}`}
                                                                        data={data}
                                                                        style={areaStyle(graph_3, true)[1]}
                                                                    />
                                                                </VictoryGroup> :
                                                                <VictoryGroup>
                                                                    <VictoryLine
                                                                        interpolation={'monotoneX'}
                                                                        x={'time'}
                                                                        y={`${graph_3}1`}
                                                                        data={data}
                                                                        style={areaStyle(graph_3, true)[0]}
                                                                    />
                                                                    <VictoryLine
                                                                        interpolation={'monotoneX'}
                                                                        x={'time'}
                                                                        y={`${graph_3}2`}
                                                                        data={data}
                                                                        style={areaStyle(graph_3, true)[1]}
                                                                    />
                                                                    {deviceActive?.phases === 2 ? null :
                                                                        <VictoryLine
                                                                            interpolation={'monotoneX'}
                                                                            x={'time'}
                                                                            y={`${graph_3}3`}
                                                                            data={data}
                                                                            style={areaStyle(graph_3, true)[2]}
                                                                        />}
                                                                </VictoryGroup>}
                                                            <VictoryAxis dependentAxis tickLabelComponent={<VictoryLabel dx={40} dy={-10} textAnchor={'middle'} verticalAnchor={'middle'} backgroundPadding={10} backgroundStyle={tickStyle} backgroundComponent={<Rect rx={8} ry={8}></Rect>}></VictoryLabel>} style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY }} />
                                                        </VictoryChart>
                                                        <div className="position-absolute right-0 top-0 mt-4 me-2 zindex-1">
                                                            <button className="row-center bg-gray-800 rounded-8 p-2"
                                                                onClick={() => {
                                                                    if (!typeLines) {
                                                                        setGraph_1(graph_3);
                                                                        handlerIndexVariable(graph_3)
                                                                        setGraph_3(graph_1);
                                                                    } else {
                                                                        setThirdLine(firstLine);
                                                                        setFirstLine(thirdLine);
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center txt-white txt-10" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>}
                                        <div className="row-between align-items-center" style={{ paddingRight: 0 }}>
                                            <p className="txt-phrase txt-medium txt-white txt-8">{moment.utc(domain?.x[0]).format('HH:mm:ss')}</p>
                                            <p className="txt-phrase txt-medium txt-white txt-8">{moment.utc((domain?.x[0] + domain?.x[1]) / 2).format('HH:mm:ss')}</p>
                                            <p className="txt-phrase txt-medium txt-white txt-8">{moment.utc(domain?.x[1]).format('HH:mm:ss')}</p>
                                        </div>
                                    </Fragment> :
                                    <div className="column-center align-items-center w-100 mt-2" style={{ height: 400 }}>
                                        <img src={empty_data_device} alt="empty_data_device" style={{ width: 270, height: 190 }} />
                                        <p className="txt-phrase txt-semibold txt-8 txt-white mt-4">{props.language.plus_graph_text_empty_data_device}</p>
                                        <p className="txt-phrase txt-regular txt-8 txt-gray-95">{props.language.plus_graph_text_last_connection}{props.lastConection}</p>
                                        <button
                                            className="row-center align-items-center txt-phrase txt-bold txt-8 bg-gray-500 border-2 border-color-green-500 txt-green-500 py-2 rounded-8 px-4 mt-4"
                                            onClick={async () => {
                                                setDay(props.lastDay);
                                                //setPage(props.lastPage);
                                                await props.loadHistoryData({ page: props.lastPage, day: props.lastDay, type: 0 }, true);
                                            }}>
                                            {props.language.plus_graph_button_show_last_records_device}
                                        </button>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state, ownProps) => ({
    dataGraph: state.plus.dataGraph,
    loadingData: state.plus.loadingInitData,
    enableHistory: state.plus.enableHistory,
    dataGraphPage: state.plus.dataGraphPage,
    graphContinerWidth: ownProps.graphContinerWidth,
    devices: state.plus.devices,
    activeServicesPlus: state.services.activeServicesPlus,
    language: state.shared.language,
    lastConection: state.plus.lastConection,
    lastDay: state.plus.lastDay,
    lastPage: state.plus.lastPage,
    account: state.accounts.account,
})

const mapDispatchToProps = dispatch => ({
    loadInitData: page => dispatch(plus.loadInitData(page)),
    loadHistoryData: (page, loading) => dispatch(plus.loadHistoryData(page, loading)),
    setEnableHistory: payload => dispatch(plus.setEnableHistory(payload)),
    loadDevices: () => dispatch(plus.loadDevices()),
    resetData: () => dispatch(plus.resetData()),
    loadServicesAsync: () => dispatch(servicesActions.loadServicesAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Graphs)