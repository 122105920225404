import * as invoices from '../creators/Invoices';

import { SETData, GETData, GETS3PublicUrl } from '../../services/WebServices';

export const setInvoices = payload => {
  return {
    type: invoices.SET_INVOICES,
    payload
  }
}

export const setInvoicesLoading = payload => {
  return {
    type: invoices.SET_INVOICES_LOADING,
    payload
  }
}

export const setInvoicesContentLoading = payload => {
  return {
    type: invoices.SET_INVOICES_CONTENT_LOADING,
    payload
  }
}

export const setInvoicesDate = payload => {
  return {
    type: invoices.SET_INVOICES_DATE,
    payload
  }
}

export const setSelectedInvoices = payload => {
  return {
    type: invoices.SET_SELECTED_INVOICES,
    payload
  }
}

export const setBanks = payload => {
  return {
    type: invoices.SET_INVOICES_BANKS,
    payload
  }
}

export const setInvoicesToPay = payload => {
  return {
    type: invoices.SET_INVOICES_TO_PAY,
    payload
  }
}

export const setInvoicesContent = payload => {
  return {
    type: invoices.SET_INVOICES_CONTENT,
    payload
  }
}

export const setShowInvoiceDetails = payload => {
  return {
    type: invoices.SET_SHOW_INVOICE_DETAILS,
    payload
  }
}

export const setShowInvoiceDetailsContent = payload => {
  return {
    type: invoices.SET_SHOW_INVOICE_DETAILS_CONTENT,
    payload
  }
}

export const setShowInvoiceDetailsIndex = payload => {
  return {
    type: invoices.SET_SHOW_INVOICE_DETAILS_INDEX,
    payload
  }
}

export const setInvoicesPeriods = payload => {
  return {
    type: invoices.SET_INVOICES_PERIODS,
    payload
  }
}

export const setFilterStatus = payload => {
  return {
    type: invoices.SET_INVOICES_FILTER_STATUS,
    payload
  }
}

export const setTotalInvoicesByPeriod = payload => {
  return {
    type: invoices.SET_INVOICES_TOTAL_INVOICES_BY_PERIOD,
    payload
  }
}

export const loadInvoicesAsync = (payload = null) => {
  return async (dispatch, getState) => {

    !payload && dispatch(setInvoicesLoading(true));

    let invoicesPeriods = getState().invoices.invoicesPeriods;
    let filterStatus = getState().invoices.filterStatus;    

    const userId = getState().users.user.idUser;
    const date = getState().invoices.invoicesDate;

    const servicesIds = getState().services.activeServicesInvoices
      .map(s => s.idService);

    const loadInvoices = () => {
      const metadata = {
        id_user: userId,
        id_services: servicesIds,
        month: payload ? payload.month : date.getMonth() + 1,
        year: payload ? payload.year : date.getFullYear()
      };

      let index = invoicesPeriods.findIndex(i => {

        const date = new Date(i.billing_date);

        return date.getMonth() + 1 === metadata.month && date.getFullYear() === metadata.year && i.invoices

      });

      if (index >= 0) {

        if (!payload) {
          dispatch(setInvoices(invoicesPeriods[index].invoices));
          dispatch(setInvoicesLoading(false));
          dispatch(setSelectedInvoices([...invoicesPeriods[index].invoices.map(i => i.id_bill)]));
        };

      } else {

        SETData('bills', 'POST', metadata)
          .then(response => {
            if (response !== null) {

              let periodId = response[0].id_period;

              let index = invoicesPeriods.findIndex(i => i.id_period === periodId);
              invoicesPeriods[index].invoices = response;
              setInvoicesPeriods([...invoicesPeriods]);

              dispatch(setSelectedInvoices([...invoicesPeriods[index].invoices.map(i => i.id_bill)]));

              if (filterStatus) {
                dispatch(setFilterStatus(filterStatus));
              }

              !payload && dispatch(setInvoices(response));

            } else {
              dispatch(setInvoices([]));
            }
          })
          .catch(response => console.error(response))
          .finally(() => !payload && dispatch(setInvoicesLoading(false)));

      };
    };

    const sectionServices = (array, size) => {
      const subarrays = [];
      for (let i = 0; i < array.length; i += size) {
        subarrays.push(array.slice(i, i + size));
      }
      return subarrays;
    }

    const loadInvoicesInBatches = async (arrayServices) => {
      const metadata = {
        id_user: userId,
        id_services: arrayServices,
        month: payload ? payload.month : date.getMonth() + 1,
        year: payload ? payload.year : date.getFullYear()
      };

      let index = invoicesPeriods.findIndex(i => {

        const date = new Date(i.billing_date);

        return date.getMonth() + 1 === metadata.month && date.getFullYear() === metadata.year && i.invoices

      });

      if (index >= 0) {

        if (!payload) {
          dispatch(setInvoices(invoicesPeriods[index].invoices));
          dispatch(setInvoicesLoading(false));
          dispatch(setSelectedInvoices([...invoicesPeriods[index].invoices.map(i => i.id_bill)]));
        };

      } else {
        try {
          const response = await SETData('bills', 'POST', metadata);
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      };
    };

    if (servicesIds.length < 20) { loadInvoices() }
    else {
      const servicesArray = sectionServices(servicesIds, 20);
      let allResponses = [];

      if (servicesArray.length) {
        try {
          for (let i = 0; i < servicesArray.length; i += 3) {
            const currentSetOfPromises = servicesArray.slice(i, i + 3).map((array, index) => {
              return loadInvoicesInBatches(array);
            });

            const currentSetResponses = await Promise.all(currentSetOfPromises);

            allResponses = allResponses.concat(currentSetResponses);
          }

          if (allResponses.length) {

            const response = allResponses.flat();

            let periodId = response[0].id_period;

            let index = invoicesPeriods.findIndex(i => i.id_period === periodId);
            invoicesPeriods[index].invoices = response;
            setInvoicesPeriods([...invoicesPeriods]);

            dispatch(setSelectedInvoices([...invoicesPeriods[index].invoices.map(i => i.id_bill)]));

            if (filterStatus) {
              dispatch(setFilterStatus(filterStatus));
            }

            !payload && dispatch(setInvoices(response));

          } else {
            dispatch(setInvoices([]));
          }
        } catch (error) {
          console.error(error);
        } finally {
          !payload && dispatch(setInvoicesLoading(false));
        }
      }

    };
  };
};

export const loadInvoicesPeriodsAsync = () => {
  return (dispatch, getState) => {

    const userId = getState().users.user.idUser;

    const servicesIds = getState().services.activeServicesInvoices
      .map(s => s.idService);

    const metadata = {
      id_user: userId,
      id_services: servicesIds
    };

    SETData('bills/periods/total', 'POST', metadata)
      .then(response => {
        if (response !== null) {
          dispatch(setInvoicesPeriods(response));
        }
      })
      .catch(response => console.error(response))

  };
};

export const loadBankDataAsync = () => {
  return (dispatch) => {

    GETData("payvalida/banks", "GET")
      .then(response => {
        if (response !== null) {
          dispatch(setBanks(response.DATA));
        }
      })
      .catch(response => console.error(response));

  }
};

export const loadInvoiceDetailsContent = () => {
  return (dispatch, getState) => {

    let invoicesPeriods = getState().invoices.invoicesPeriods;

    const date = getState().invoices.invoicesDate;
    const invoicesContent = getState().invoices.invoicesContent;
    const invoiceDetails = getState().invoices.invoiceDetails;
    const activeServicesInvoices = getState().services.activeServicesInvoices.sort((a, b) => a.idService - b.idService);

    let periodInfo = invoicesPeriods.find(i => i.id_period === invoiceDetails.id_period);
    let content = periodInfo.invoicesContent?.find(s => s.id_bill === invoiceDetails.id_bill);

    const periodDate = new Date(periodInfo.billing_date);

    if (content !== undefined) {

      dispatch(setShowInvoiceDetailsContent(content));
      dispatch(setInvoicesContentLoading(false));

    } else {

      dispatch(setInvoicesContentLoading(true));

      const userId = getState().users.user.idUser;

      const servicesIdsLoaded = periodInfo.invoicesContent?.map(i => i.id_service) || [];

      const servicesIds = activeServicesInvoices
        .filter(i => !servicesIdsLoaded.includes(i.idService))
        .map(s => s.idService).slice(0, 6);

      servicesIds.push(invoiceDetails.id_service)

      const metadata = {
        id_user: userId,
        id_services: [...new Set(servicesIds)],
        month: periodDate.getMonth() + 1,
        year: periodDate.getFullYear()
      };

      SETData('bills/content', 'POST', metadata)
        .then(response => {
          if (response !== null) {

            if (periodDate.getMonth() !== date.getMonth() && periodDate.getFullYear() !== date.getFullYear()) {
              dispatch(setInvoicesContent(response));
            } else {
              dispatch(setInvoicesContent([...invoicesContent, ...response]));
            }

            content = response.find(s => s.id_service === invoiceDetails.id_service);
            dispatch(setShowInvoiceDetailsContent(content ? content : null));
            content && dispatch(setInvoicesContentLoading(false));

            let index = invoicesPeriods.findIndex(i => i.id_period === invoiceDetails.id_period);
            invoicesPeriods[index].invoicesContent = [...invoicesPeriods[index].invoicesContent || [], ...response];
            setInvoicesPeriods([...invoicesPeriods]);

          }
        })
        .catch(response => console.error(response));

    }

  }
}

export const downloadInvoiceAsync = (payload) => {
  return () => {

    const url = GETS3PublicUrl(payload);

    fetch(url)
      .then(response => response.blob())
      .then(blob => {

        const n = payload.lastIndexOf('/');
        const filename = payload.substring(n + 1);

        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }
}

