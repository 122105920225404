import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import * as servicesActions from "../../store/actions/Services"
import * as geniusActions from "../../store/actions/Genius"

import { add, format, parseISO, startOfMonth } from "date-fns"
import esLocale from "date-fns/locale/es"
import enLocale from "date-fns/locale/en-US"

import { Animation, Alert } from "rsuite"

import ContentLoader from "react-content-loader"

import Header from "../shared/Header"
import ActiveReports from "./ActiveReports"
import Picker from "./Picker"
import TextTooltip from "./TextTooltip"
import ExtraItemsPopover from "./ExtraItemsPopover"
import ExportReport from "./ExportReport"
import CalendarRangePicker from "../shared/utilities/CalendarRangePicker"
import ServicesPopover from "../energy/services/ServicesPopover"

import empty_reports_genius from "../../imgs/empty-reports-genius.svg"

import { track } from "@amplitude/analytics-browser"

import IframeResizer from "iframe-resizer-react"
import { startOfToday } from "date-fns"

const loadingContent = <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
  <ContentLoader
    seed={2}
    backgroundColor="#161617"
    foregroundColor="#323234"
    style={{ width: "100%", height: "100%" }}>
    <rect x="0" y="0" width="calc(100%)" height="10000" rx="10" ry="10"></rect>
  </ContentLoader>
</div>;

const isValidReport = (report, totalReports) => {
  if (report.name === null || report.name === "") {
    return false
  }

  if (totalReports.map((r) => r?.file_name.split(".")[0].toLowerCase()).includes(report?.name.toLocaleLowerCase())) {
    return false
  }

  if (report.deviceIds === null || !report.deviceIds.length) {
    return false
  }

  if (report.graphIds === null || !report.graphIds.length) {
    return false
  }

  if (report.dateFrom === null || report.dateFrom === "") {
    return false
  }

  if (report.dateTo === null || report.dateTo === "") {
    return false
  }

  return true
}

const showError = (report, totalReports, language) => {
  if (report.name === null || report.name === "" || report.duplicateReport) {
    return Alert.error(language.alert_genius_create_report_filename, 5000)
  }

  if (totalReports.map((r) => r?.file_name.split(".")[0].toLowerCase()).includes(report?.name.toLocaleLowerCase())) {
    return Alert.error(language.alert_genius_create_report_duplicate_filename, 5000)
  }

  if (report.deviceIds === null || !report.deviceIds.length) {
    return Alert.error(language.alert_genius_create_report_devices, 5000)
  }

  if (report.graphIds === null || !report.graphIds.length) {
    return Alert.error(language.alert_genius_create_report_graphs, 5000)
  }

  if (report.dateFrom === null || report.dateFrom === "" || report.dateTo === null || report.dateTo === "") {
    return Alert.error(language.alert_genius_create_report_range, 5000)
  }
}



const Genius = (props) => {
  const initReport = {
    name: "",
    graphIds: [],
    deviceIds: [],
    dateFrom: null,
    dateTo: null,
    duplicate: false,
  }

  /* Data of the new report that can be created by the user */
  const [report, setReport] = useState(initReport)
  const [isEnable, setEnable] = useState(false)
  /* Report that is expanded and is selected in <ActiveReports /> */
  const [reportDetailed, setReportDetailed] = useState(null)
  /* Show calendarRangePicker when creating a new report */
  const [showCalendar, setShowCalendar] = useState(false)
  /* Date range of the new report calendar picker  */
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: startOfToday(),
      key: "selection",
    },
  ])
  /* Data of services and graphs that is selected in the picker for new report creation */
  const [serviceSelected, setServiceSelected] = useState([])
  const [graphSelected, setGraphSelected] = useState([])
  const [typeOfReport, setTypeOfReport] = useState([])
  const [selectedTypeOfReport, setSelectedTypeOfReport] = useState([])

  const [premium, setPremium] = useState(false)
  const [isNavbarFixed, setIsNavbarFixed] = useState(false)
  const [externalShowEvent, setExternalShowEvent] = useState(false)

  let locale = props.language.language_locale === "es" ? esLocale : enLocale
  let account = props.account
  let totalReports = props.totalReports
  let loadingReport = props.loadingReport
  let reportGenerated = props.reportGenerated
  let totalServices = props.totalServices
  let graphs = props.graphs
  let expanded = props.expanded
  let newReport = props.newReport
  let activeServicesPlus = props.activeServicesPlus
  let serviceType = props.serviceType
  let showBlackScreen = props.showBlackScreen
  let language = props.language

  const loadServicesAsync = props.loadServicesAsync
  const getReportsAsync = props.getReportsAsync
  const generateReportAsync = props.generateReportAsync
  const setNewReport = props.setNewReport
  const setShowContentHeader = props.setShowContentHeader
  const getGraphsReportAsync = props.getGraphsReportAsync

  const dataGroupGraphs = [
    { id: 1, group: props.language.genius_report_monthly_consumption },
    { id: 2, group: props.language.genius_report_submeasurements},
    { id: 3, group: props.language.genius_report_detailed_consumption },
    { id: 4, group: props.language.genius_report_reactive_energy },
    { id: 5, group: props.language.genius_report_savings },
    { id: 6, group: props.language.genius_report_generation},
  ]

  /* When the account change, restart everything */
  useEffect(() => {    
    account && loadServicesAsync()
    setServiceSelected([])
    setGraphSelected([])
    setReport({
      name: "",
      graphIds: [],
      deviceIds: [],
      dateFrom: null,
      dateTo: null,
      duplicate: false,
    })
    setNewReport(false)
    setShowContentHeader(false)

  }, [account, loadServicesAsync, setNewReport, setShowContentHeader])

  useEffect(() => {
    setReport({
      name: "",
      graphIds: [],
      deviceIds: [],
      dateFrom: null,
      dateTo: null,
      duplicate: false,
    })
  }, [serviceType])

  useEffect(() => {
    if (account && totalServices) getReportsAsync()
  }, [account, reportGenerated, getReportsAsync, totalServices])

  useEffect(() => { getGraphsReportAsync() }, [getGraphsReportAsync])

  useEffect(() => {
    if (report.deviceIds) {
      setServiceSelected(totalServices.filter((service) => Array.isArray(report.deviceIds) ? report.deviceIds.includes(service.idService) : report.deviceIds === service.idService))
    } else {
      setServiceSelected([])
    }

    if (report.graphIds.length) {
      setGraphSelected(graphs.filter((graph) => report.graphIds.includes(graph.id_graph)))
    }
  }, [graphs, report, totalServices])

  useEffect(() => {
    setReport(prevReport => ({
      ...prevReport,
      dateFrom: format(dateRange[0].startDate, "yyyy-MM-dd HH:mm:ss"),
      dateTo: format(dateRange[0].endDate, "yyyy-MM-dd HH:mm:ss")
    }));
  }, [dateRange])

  useEffect(() => {
    setEnable(isValidReport(report, totalReports))
  }, [setEnable, report, totalReports])

  useEffect(() => {
    if (totalServices.length === 1) {
      setReport(prevReport => ({
        ...prevReport,
        deviceIds: [totalServices[0].idService]
      }));
    }   

    const types = [];
    const generationIds = [4];
    const consumptionIds = [1, 2, 3];

    const generationServices = totalServices.filter((s => { return generationIds.includes(s.type?.typeService) }))
    const consumptionServices = totalServices.filter((s => { return consumptionIds.includes(s.type?.typeService) }))

    if (generationServices.length > 0) {
      types.push({ name: language.generation_report, services: generationIds, graphs: [6] })
    }

    if (consumptionServices.length > 0) {
      types.push({ name: language.consumption_report, services: consumptionIds, graphs: [1, 2, 3, 4, 5] })
    }

    setTypeOfReport(types)

  }, [totalServices, language])

  useEffect(() => {
    if (!showBlackScreen && selectedTypeOfReport[0]) {

      const filteredTypeReport = typeOfReport.filter(type => type.name === selectedTypeOfReport[0])

      track('Report created')
      props.setServiceType(filteredTypeReport[0] && filteredTypeReport[0].services)
      props.setGraphsType(filteredTypeReport[0] && filteredTypeReport[0].graphs)
      props.setNewReport(true)
      props.setShowContentHeader(true)
    }

  }, [selectedTypeOfReport, showBlackScreen, typeOfReport, props])

  /* Hide the calendar when the report detail is false */
  useEffect(() => {
    if (!expanded) {
      setShowCalendar(false)
    }
  }, [expanded])

  useEffect(() => {
    if (activeServicesPlus.length) {
      setPremium(true)
    } else setPremium(false)
  }, [activeServicesPlus, setPremium, account])

  const scrollToReport = (id_report) => {
    const reportSelector = document.querySelector(`#report${id_report}`)
    reportSelector.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (graphSelected.length) {
      track('Variable selected', { Genius_Variable_ID: graphSelected[graphSelected.length - 1]?.description })
    }
  }, [graphSelected])

  return (
    <section className="column-start align-items-center vh-100 w-100">
      <Header moduleName={props.language.menu_genius_service} backToButton={false} />
      <div className="column-start align-items-center flex-grow-1 w-100">
        {!loadingReport ?
          <div className="position-relative w-100 mb-3 px-3">
            <div style={{ paddingTop: isNavbarFixed ? 16 : 0, position: 'sticky', top: -1 }} className={`column-start w-100 bg-black ${props.showBlackScreen ? "zindex-5" : "zindex-2"}`}>
              <div className={`position-relative column-start bg-gray-500 border-1 border-color-gray-800 rounded-top-16 p-3 w-100 ${props.showBlackScreen || props.showMenu ? "border-transparent" : ""}`}>
                {props.showBlackScreen && <div className="position-absolute top-0 left-0 rounded-top-16 bg-black-80 w-100 h-100 zindex-6"></div>}
                <p className="txt-gray-600 txt-phrase txt-6 txt-regular">{props.language.genius_title_main_reports}</p>
                {props.totalReports.length ?
                  <div className="row-between mt-3">
                    <ActiveReports
                      showActiveReports={4}
                      reportDetailed={reportDetailed}
                      setReportDetailed={setReportDetailed}
                      scrollToReport={scrollToReport}
                      setSelectedTypeOfReport={setSelectedTypeOfReport} />
                    {!expanded ?
                      <div className="row-center gap-2">
                        <Picker
                          keyName="name"
                          labelName="name"
                          labelButton={props.language.genius_done_button}
                          icon={"circle-plus"}
                          height={32}
                          width={32}
                          allowSearch={false}
                          allowAllOptions={false}
                          labelAllServices={props.language.report_type}
                          options={typeOfReport}
                          selectedData={selectedTypeOfReport}
                          onChange={(value) => setSelectedTypeOfReport(value)}
                          outsideButtonEvent="GE Open Report Picker"
                          insideButtonEvent="GE Save Selection Reports"
                          buttonText={props.language.genius_save_report}
                          typeReport={true}
                          externalShowEvent={externalShowEvent}
                          setExternalShowEvent={setExternalShowEvent}
                        />
                        <hr className="txt-gray-700 m-0 border-1 border-gray-800" style={{ height: "30px" }}></hr>
                        <Picker
                          keyName="id_report"
                          labelName="file_name"
                          filterName="file_name"
                          labelDetail="created_time"
                          labelButton={props.language.genius_done_button}
                          icon={"circle-plus"}
                          height={32}
                          width={32}
                          report={true}
                          allowSearch={true}
                          allowAllOptions={false}
                          showSelected={true}
                          labelAllServices={props.language.genius_title_main_reports}
                          options={props.totalReports}
                          selectedData={props.activeReports}
                          onChange={(value) => props.setActiveReports(value)}
                          outsideButtonEvent="GE Open Report Picker"
                          insideButtonEvent="GE Save Selection Reports"
                        />
                      </div> :
                      !newReport ?
                        <div className="row-start align-items-center gap-2">
                          <div className="pe-pointer bg-gray-450 border-1 border-color-gray-800 rounded-30 py-2 pe-3"
                            onClick={() => props.deleteReportAsync(reportDetailed.id_report)}>
                            <div className="row-start align-items-center h-100">
                              <div className="row-center align-items-center min-width-32">
                                <i className="fa-light fa-trash txt-8 txt-gray-700" />
                              </div>
                              <span className="txt-phrase txt-8 txt-overflow-unset txt-white pe-pointer">{props.language.genius_delete_report}</span>
                            </div>
                          </div>
                          {/* <div
                            className="pe-pointer bg-gray-450 border-1 border-color-gray-800 rounded-30 py-2 pe-3"
                            onClick={() => {
                              props.downloadReportsAsync(`${reportDetailed.id_account}/${reportDetailed.file_name}`)
                            }}>
                            <div className="row-start align-items-center h-100">
                              <div className="row-center align-items-center min-width-32">
                                <i className="fa-light fa-arrow-down txt-8 txt-gray-700" />
                              </div>
                              <span className="txt-phrase txt-8 txt-overflow-unset txt-white pe-pointer">{props.language.genius_download_report}</span>
                            </div>
                          </div> */}
                          <ExportReport htmlUrl={`${reportDetailed.id_account}/${reportDetailed.file_name}`} excelUrl={`${reportDetailed.file_excel}`} />
                        </div> :
                        <div className="row-start gap-2">
                          <button
                            className="row-center align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 py-2 px-3"
                            onClick={() => {
                              if (isEnable) {
                                generateReportAsync(report)
                                props.setNewReport(false)
                                props.setShowContentHeader(false)
                                setReport(initReport)
                                track('Report created', { Genius_Variable_ID: graphSelected.map(function (graph) { return graph?.description }) })
                              } else {
                                showError(report, totalReports, props.language)
                              }
                            }}>
                            <i className="fa-light fa-circle-check txt-12 txt-green-500 me-2" />
                            <p className="txt-phrase txt-regular txt-green-500 txt-8 pe-pointer">{props.language.genius_save_report}</p>
                          </button>
                          <button
                            className="row-center align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 py-2 px-3"
                            onClick={() => {
                              setSelectedTypeOfReport([])
                              props.setNewReport(false)
                              props.setShowContentHeader(false)
                              setReport(initReport)
                            }}>
                            <i className="fa-light fa-circle-xmark txt-12 txt-gray-700 me-2" />
                            <p className="txt-phrase txt-regular txt-white txt-8 pe-pointer">{props.language.genius_cancel_report}</p>
                          </button>
                        </div>
                    }
                  </div> :
                  <div className="row-between mt-3">
                    <ActiveReports setSelectedTypeOfReport={setSelectedTypeOfReport} />
                    {!newReport ?
                      <Picker
                        keyName="name"
                        labelName="name"
                        filterName="name"
                        labelButton={props.language.genius_done_button}
                        icon={"circle-plus"}
                        height={32}
                        width={32}
                        allowSearch={false}
                        allowAllOptions={false}
                        labelAllServices='Tipo de reporte'
                        options={typeOfReport}
                        selectedData={selectedTypeOfReport}
                        onChange={(value) => setSelectedTypeOfReport(value)}
                        outsideButtonEvent="GE Open Report Picker"
                        insideButtonEvent="GE Save Selection Reports"
                        typeReport={true}
                        externalShowEvent={externalShowEvent}
                        setExternalShowEvent={setExternalShowEvent}
                      /> :
                      <div className="row-start align-items-center gap-2">
                        <button
                          className="row-center align-items-center rounded-pill bg-gray-450 p-2"
                          onClick={() => {
                            if (isEnable) {
                              generateReportAsync(report)
                              props.setNewReport(false)
                              props.setShowContentHeader(false)
                              setReport(initReport)
                              track('Report created', { Genius_Variable_ID: graphSelected.map(function (graph) { return graph?.description }) })
                            } else {
                              showError(report, totalReports, props.language)
                            }
                          }}>
                          <i className="fa-light fa-circle-check txt-8 txt-gray-700 me-2" />
                          <p className="txt-phrase txt-regular txt-white txt-8 pe-pointer">{props.language.genius_save_report}</p>
                        </button>
                        <button
                          className="row-center align-items-center rounded-pill bg-gray-450 p-2"
                          onClick={() => {
                            props.setNewReport(false)
                            props.setShowContentHeader(false)
                            setSelectedTypeOfReport([])
                            setReport(initReport)
                          }}>
                          <i className="fa-light fa-circle-xmark txt-8 txt-gray-700 me-2" />
                          <p className="txt-phrase txt-regular txt-white txt-8 pe-pointer">{props.language.genius_cancel_report}</p>
                        </button>
                      </div>
                    }
                  </div>}
                <Animation.Collapse in={expanded} dimension={"height"}>
                  <div className="column-start">
                    <div className="row-between w-100 align-items-center my-3 py-3 border-top-1 border-bottom-1 border-color-gray-800">
                      <div className="row-start align-items-center">
                        <p className="txt-gray-600 txt-phrase txt-6 me-2">{props.language.genius_name_report}</p>
                        {!newReport ?
                          <div className="row-start rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 min-width-100">
                            <p className="txt-white txt-phrase txt-6">{reportDetailed?.file_name && reportDetailed.file_name.split(".")[0]}</p>
                          </div> :
                          <input
                            value={report.name}
                            type="text"
                            autoFocus={true}
                            className="rounded-pill bg-gray-450 py-2 px-3 txt-white txt-phrase txt-6"
                            placeholder={props.language.genius_new_report}
                            style={{ border: "none" }}
                            onChange={({ target }) => {
                              setReport({ ...report, name: target.value })
                            }}></input>
                        }
                      </div>
                      <div className="row-start align-items-center gap-2">
                        <p className="txt-gray-600 txt-phrase txt-6">{props.language.genius_date_range_report}</p>
                        {newReport &&
                          <div className="row-center align-items-center rounded-30 bg-gray-450 border-1 border-color-gray-800 p-2 pe-pointer" style={{ height: 32, width: 32 }} onClick={() => setShowCalendar(!showCalendar)}>
                            <i className="fal fa-circle-caret-down txt-10 txt-gray-700 pe-pointer"></i>
                          </div>
                        }
                        <div className={`row-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 ${newReport ? "pe-pointer" : ""}`} onClick={() => { if (newReport) { setShowCalendar(!showCalendar) } }}>
                          <p className="txt-white txt-phrase txt-6 txt-capitalize">
                            {newReport ? format(dateRange[0].startDate, "eee. dd, LLLL", { locale: locale }) : reportDetailed?.start_date ? /* add 1 day because ISO date is displayed as the previous day */ format(add(parseISO(reportDetailed.start_date), { days: 1 }), "eee. dd, LLLL", { locale: locale }) : null}
                          </p>
                        </div>
                        <div className="position-relative column-center align-items-center">
                          <CalendarRangePicker show={showCalendar} setShow={setShowCalendar} dateRange={dateRange} setDateRange={setDateRange} language={props.language}/>
                        </div>
                        <div className={`row-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 ${newReport ? "pe-pointer" : ""}`} onClick={() => { if (newReport) { setShowCalendar(!showCalendar) } }}>
                          <p className="txt-white txt-phrase txt-6 txt-capitalize">{newReport ? format(dateRange[0].endDate, "eee. dd, LLLL", { locale: locale }) : reportDetailed?.end_date ? format(add(parseISO(reportDetailed.end_date), { days: 1 }), "eee. dd, LLLL", { locale: locale }) : null}</p>
                        </div>
                      </div>
                      <div className="row-start align-items-center">
                        <p className="txt-gray-600 txt-phrase txt-6">{props.language.genius_creation_date_report}</p>
                        <div className="ms-2 row-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2">
                          <p className="txt-white txt-phrase txt-6 txt-capitalize">{newReport ? format(new Date(), "eee. dd, LLLL", { locale: locale }) : reportDetailed?.created_time ? format(parseISO(reportDetailed.created_time), "eee. dd, LLLL", { locale: locale }) : null}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row-start w-100 align-items-center gap-2">
                      {!newReport ?
                        <div className="column-start w-50">
                          <div className="row-start w-100 align-items-center w-100">
                            <p className="txt-gray-600 txt-phrase txt-6">{props.language.genius_services_report}</p>
                            <div className="ms-2 row-center rounded-pill bg-gray-450 py-1 px-2">
                              <p className="txt-gray-600 txt-phrase txt-6">{reportDetailed?.id_services && `${reportDetailed.id_services.length} ${props.language.genius_selected_items}`}</p>
                            </div>
                          </div>
                          <div className="row-start align-items-center mt-3 gap-2">
                            {reportDetailed?.id_services.slice(0, 2)
                              .map((service, i) => (
                                <TextTooltip key={i} bgColor={"black-opacity"}
                                  content={
                                    <div className="px-3 py-2">
                                      <p className="rounded-pill py-1 px-2 txt-white txt-phrase txt-6">{service.name}</p>
                                    </div>
                                  }
                                  text={
                                    <div className="max-width-170 max-width-270-2xl overflow-hidden text-nowrap rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 txt-white txt-phrase txt-6" style={{ textOverflow: "ellipsis" }}>
                                      {service.name}
                                    </div>
                                  }
                                />
                              ))}
                            {reportDetailed?.id_services.length > 2 && (
                              <ExtraItemsPopover
                                content={
                                  <div className="w-100 p-2">
                                    <div className="column-start">
                                      {reportDetailed.id_services
                                        .slice(2)
                                        .map(service => (
                                          <div className="row-start align-items-center p-2" key={service.id}>
                                            <p className="txt-white txt-phrase txt-6">{service.name}</p>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                }
                                button={
                                  <div className="row-center align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2">
                                    <p className="txt-white txt-phrase txt-6">+{reportDetailed.id_services.length - 2}</p>
                                    <i className="fal fa-circle-caret-down txt-6 txt-gray-700 ms-2"></i>
                                  </div>
                                }
                              />
                            )}
                          </div>
                        </div> :
                        <div className="column-start w-50">
                          <div className="row-start w-100 align-items-center w-100">
                            <p className="txt-gray-600 txt-phrase txt-6">{props.language.genius_services_report}</p>
                          </div>
                          <div className="row-start align-items-center gap-2 mt-3">
                            <div id="service_picker">
                              <ServicesPopover
                                activeServices={serviceSelected}
                                selectFunction={(value) => {
                                  setReport({ ...report, deviceIds: value.map(v => v.idService) })
                                }}
                                availableTypeServices={serviceType.includes(4) ? ["generation"] : ["energy", "plus"]}
                                limitSelectServices={serviceType.includes(4) ? 1 : null}
                                typeComponent={'energy'}
                                popoverPosition={'bottomLeft'}
                              />
                            </div>
                            {!report.deviceIds && !serviceSelected.length ?
                              <div className="row-center align-items-center rounded-pill p-2 bg-gray-450 border-1 border-color-gray-800">
                                <p className="txt-phrase txt-regular txt-6 txt-white">+ {props.language.genius_add_services}</p>
                              </div> :
                              serviceSelected.length > 2 ?
                                <div className="row-start align-items-center gap-2">
                                  {serviceSelected
                                    .slice(0, 2)
                                    .map((service, i) => (
                                      <TextTooltip
                                        key={i}
                                        bgColor={"black-opacity"}
                                        content={
                                          <div className="px-3 py-2">
                                            <p className="rounded-pill py-1 px-2 txt-white txt-phrase txt-6">{service.name}</p>
                                          </div>
                                        }
                                        text={
                                          <div className="max-width-170 max-width-270-2xl overflow-hidden text-nowrap rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 txt-white txt-phrase txt-6" style={{ textOverflow: "ellipsis" }}>
                                            {service.name}
                                          </div>
                                        }
                                      />
                                    ))}
                                  <ExtraItemsPopover
                                    content={
                                      <div className="w-100 p-2">
                                        <div className="column-start">
                                          {serviceSelected
                                            .slice(2)
                                            .map((s, i) => (<div key={i} className="row-start align-items-center txt-white txt-phrase txt-6 p-2">{s.name}</div>))}
                                        </div>
                                      </div>
                                    }
                                    button={
                                      <div className="row-center align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 h-auto">
                                        <p className="txt-white txt-phrase txt-6">+ {serviceSelected.length - 2}</p>
                                      </div>
                                    }
                                  />
                                </div> :
                                serviceSelected
                                  .map((service, i) => (
                                    <TextTooltip
                                      key={i}
                                      bgColor={"black-opacity"}
                                      content={
                                        <div className="px-3 py-2">
                                          <p className="rounded-pill py-1 px-2 txt-white txt-phrase txt-6">{service.name}</p>
                                        </div>
                                      }
                                      text={
                                        <div className="max-width-170 max-width-270-2xl overflow-hidden text-nowrap rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 txt-white txt-phrase txt-6" style={{ textOverflow: "ellipsis" }}>
                                          {service.name}
                                        </div>
                                      }
                                    />
                                  ))
                            }
                          </div>
                        </div>}
                      {!newReport ?
                        <div className="column-start w-50">
                          <div className="row-start w-100 align-items-center w-100">
                            <p className="txt-gray-600 txt-phrase txt-6">{props.language.genius_graphs}</p>
                            <div className="ms-2 row-center rounded-pill bg-gray-450 py-1 px-2">
                              <p className="txt-gray-600 txt-phrase txt-2">{reportDetailed?.id_graphs && `${reportDetailed.id_graphs.length} ${props.language.genius_selected_items}`} </p>
                            </div>
                          </div>
                          <div className="row-start align-items-center mt-3 gap-2">
                            {reportDetailed?.id_graphs.slice(0, 2)
                              .map((_graph) =>
                              (<div className="row-center align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2" key={_graph.id_graph}>
                                <i className={`fas fa-circle txt-6 me-2 ${_graph.category === 1 ? "txt-blue-500" : _graph.category === 2 ? "txt-red-500" : _graph.category === 3 ? "txt-orange-500" : _graph.category === 4 ? "txt-purple-500" : "txt-green-500"}`} />
                                <TextTooltip bgColor={"black-opacity"}
                                  content={
                                    <div className="px-3 py-2">
                                      <p className="txt-white text-nowrap txt-phrase txt-6">{props.language[_graph.description]}</p>
                                    </div>
                                  }
                                  text={
                                    <p className="max-width-170 max-width-270-2xl overflow-hidden txt-white text-nowrap txt-phrase txt-6" style={{ textOverflow: "ellipsis" }}>
                                      {props.language[_graph.description]}
                                    </p>
                                  }
                                />
                              </div>
                              )
                              )}
                            {reportDetailed?.id_graphs.length > 2 &&
                              <ExtraItemsPopover
                                placement="bottomRight"
                                withArrow={false}
                                content={
                                  <div className="w-100 p-2">
                                    <div className="column-start">
                                      {reportDetailed.id_graphs.slice(2)
                                        .map((_graph) => (
                                          <div className="row-start align-items-center p-2" key={_graph.id_graph}>
                                            <i className={`fas fa-circle txt-6 me-2 ${_graph.category === 1 ? "txt-blue-500" : _graph.category === 2 ? "txt-red-500" : _graph.category === 3 ? "txt-orange-500" : _graph.category === 4 ? "txt-purple-500" : "txt-green-500"}`} />
                                            <p className="txt-white txt-phrase txt-6">{props.language[_graph.description]}</p>
                                          </div>
                                        )
                                        )}
                                    </div>
                                  </div>
                                }
                                button={
                                  <div className="row-center align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 pe-pointer">
                                    <p className="txt-white txt-phrase txt-6">+{reportDetailed.id_graphs.length - 2}</p>
                                    <i className="fal fa-circle-caret-down txt-6 txt-gray-700 ms-2"></i>
                                  </div>
                                }
                              />
                            }
                          </div>
                        </div> :
                        <div className="column-start w-50">
                          <div className="row-start w-100 align-items-center w-100">
                            <p className="txt-gray-600 txt-phrase txt-6">{props.language.genius_graphs}</p>
                          </div>
                          <div className="row-start align-items-center gap-2 mt-3">
                            <Picker
                              keyName="id_graph"
                              labelName="description"
                              labelButton={props.language.genius_done_button}
                              icon={"circle-caret-down"}
                              allowAllOptions={true}
                              closeOnRemoteClick={false}
                              labelAllServices={props.language.genius_graphs}
                              newData={graphs.filter((y) => y.new_graph === 1).map((g) => g.id_graph)}
                              options={graphs}
                              selectedData={report.graphIds}
                              groups={!premium ? dataGroupGraphs.filter((item) => item.id !== 2) : dataGroupGraphs}
                              onChange={(value) => {
                                setReport({ ...report, graphIds: value })
                              }}
                              outsideButtonEvent="GE Open Graphs Selection"
                              insideButtonEvent="GE Save Selection Graphs"
                              allOptionButtonEvent="GE Select All Graphs"
                            />
                            {!report.graphIds.length ?
                              <div className="row-center align-items-center rounded-pill p-2 bg-gray-450 border-1 border-color-gray-800">
                                <p className="txt-phrase txt-regular txt-6 txt-white">+ {props.language.genius_add_graphs}</p>
                              </div> :
                              graphSelected.length > 2 ?
                                <div className="row-start align-items-center gap-2">
                                  {graphSelected.slice(0, 2).map((_graph) => {
                                    return (
                                      <div className="row-center align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2" key={_graph.id_graph}>
                                        <i className={`fas fa-circle txt-6 me-2 ${_graph.category === 1 ? "txt-blue-500" : _graph.category === 2 ? "txt-red-500" : _graph.category === 3 ? "txt-orange-500" : _graph.category === 4 ? "txt-purple-500" : "txt-green-500"}`} />
                                        <TextTooltip
                                          bgColor={"black-opacity"}
                                          content={
                                            <div className="px-3 py-2">
                                              <p className="txt-white text-nowrap txt-phrase txt-6">{props.language[_graph.description]}</p>
                                            </div>
                                          }
                                          text={
                                            <p className="max-width-170 max-width-270-2xl overflow-hidden txt-white text-nowrap txt-phrase txt-6" style={{ textOverflow: "ellipsis" }}>
                                              {props.language[_graph.description]}
                                            </p>
                                          }
                                        />
                                      </div>
                                    )
                                  })}
                                  <ExtraItemsPopover
                                    placement="bottomRight"
                                    withArrow={false}
                                    content={
                                      <div className="w-100 p-2">
                                        <div className="column-start">
                                          {graphSelected.slice(2).map((_graph) => (
                                            <div className="row-start align-items-center p-2" key={_graph.id_graph}>
                                              <i className={`fas fa-circle txt-6 me-2 ${_graph.category === 1 ? "txt-blue-500" : _graph.category === 2 ? "txt-red-500" : _graph.category === 3 ? "txt-orange-500" : _graph.category === 4 ? "txt-purple-500" : "txt-green-500"}`} />
                                              <p className="txt-white txt-phrase txt-6">{props.language[_graph.description]}</p>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    }
                                    button={
                                      <div className="row-start align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2 me-2">
                                        <p className="txt-white txt-phrase txt-6">{`+${report.graphIds.length - 2}`}</p>
                                      </div>
                                    }
                                  />
                                </div> :
                                graphSelected.map((_graph) => {
                                  return (
                                    <div className="row-center align-items-center rounded-pill bg-gray-450 border-1 border-color-gray-800 p-2" key={_graph.id_graph}>
                                      <i className={`fas fa-circle txt-6 me-2 ${_graph.category === 1 ? "txt-blue-500" : _graph.category === 2 ? "txt-red-500" : _graph.category === 3 ? "txt-orange-500" : _graph.category === 4 ? "txt-purple-500" : "txt-green-500"}`} />
                                      <TextTooltip
                                        bgColor="black-opacity"
                                        content={
                                          <div className="px-3 py-2">
                                            <p className="txt-white text-nowrap txt-phrase txt-6">{props.language[_graph.description]}</p>
                                          </div>
                                        }
                                        text={
                                          <p className="max-width-170 max-width-270-2xl overflow-hidden txt-white text-nowrap txt-phrase bg-gray-450 txt-6" style={{ textOverflow: "ellipsis" }}>
                                            {props.language[_graph.description]}
                                          </p>
                                        }
                                      />
                                    </div>
                                  )
                                })
                            }
                          </div>
                        </div>}
                    </div>
                  </div>
                </Animation.Collapse>
              </div>
            </div>
            <div className="position-relative column-start bg-gray-500 border-left-1 border-right-1 border-bottom-1 border-color-gray-800 rounded-bottom-16 p-2 gap-2 w-100 min-height-500">
              {props.totalReports.length && props.activeReports.length ?
                props.activeReports.map((report) => (
                  <div key={report?.id_report} className="position-relative column-start align-items-center bg-black w-100 rounded-12">
                    <div id={`r${report?.id_report}`} className="w-100">
                      <IframeResizer id={`report${report?.id_report}`} title={report?.file_name} className={`rounded-16 ${props.newReport ? "opacity-20" : ""}`} src={report?.url} style={{ width: "1px", minWidth: "100%", scrollMarginTop: "-80px", minHeight: "600px" }} />
                    </div>
                  </div>
                )) :
                <div className="column-center align-items-center w-100" style={{ height: 500 }}>
                  <img src={empty_reports_genius} alt="empty_graph_energy" style={{ width: 167, height: 100 }} />
                  <div className="txt-gray-600 txt-phrase txt-semibold txt-10 text-wrap mt-2">{props.language.genius_title_empty_state}</div>
                  <div className="txt-gray-600 txt-phrase txt-regular txt-8 text-wrap mt-2 text-center">{props.language.genius_text_description_empty_state}</div>
                  <button
                    className="bg-green-500 rounded-8 py-2 px-3 mt-4"
                    onClick={() => setExternalShowEvent(true)}>
                    <div className="row-center align-items-center">
                      <div className="txt-phrase txt-bold txt-8 txt-neutral-800 pe-pointer">{props.language.genius_button_default_report}</div>
                    </div>
                  </button>
                </div>}
            </div>
          </div> :
          <div className="position-relative h-100 w-100 px-3 pb-3">
            <div className="position-relative w-100 h-100 overflow-hidden bg-gray-500 rounded-16">
              {loadingContent}
            </div>
          </div>}
      </div>
    </section >
  )
}

const mapStateToProps = (state) => ({
  account: state.accounts.account,
  language: state.shared.language,
  activeReports: state.genius.activeReports,
  totalReports: state.genius.totalReports,
  loadingReport: state.genius.loadingReport,
  activeServicesPlus: state.services.activeServicesPlus,
  reportGenerated: state.genius.reportGenerated,
  totalServices: state.services.totalServices,
  graphs: state.genius.graphs,
  expanded: state.genius.expanded,
  newReport: state.genius.newReport,
  showBlackScreen: state.shared.showBlackScreen,
  showMenu: state.shared.showMenu,
  serviceType: state.genius.serviceType,
  graphsType: state.genius.graphsType
})

const mapDispatchToProps = (dispatch) => ({
  loadServicesAsync: (payload) => dispatch(servicesActions.loadServicesAsync(payload)),
  getGraphsReportAsync: () => dispatch(geniusActions.getGraphsReportAsync()),
  getReportsAsync: () => dispatch(geniusActions.getReportsAsync()),
  setActiveReports: (payload) => dispatch(geniusActions.setActiveReports(payload)),
  generateReportAsync: (payload) => dispatch(geniusActions.generateReportAsync(payload)),
  downloadReportsAsync: (payload) => dispatch(geniusActions.downloadReportsAsync(payload)),
  setNewReport: (payload) => dispatch(geniusActions.setNewReport(payload)),
  setShowContentHeader: (payload) => dispatch(geniusActions.setShowContentHeader(payload)),
  deleteReportAsync: (payload) => dispatch(geniusActions.deleteReportAsync(payload)),
  setServiceType: (payload) => dispatch(geniusActions.setServiceType(payload)),
  setGraphsType: (payload) => dispatch(geniusActions.setGraphsType(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Genius)
