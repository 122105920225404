import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Animation, Drawer, Alert, Loader } from "rsuite";

import Input from "./utilities/Input";
import Select from "./utilities/Select";

import axios from "axios";

const isValidateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const isValidUser = (user) => {
  if (user.name === null || user.name === "") {
    return false;
  }

  if (user.lastname === null || user.lastname === "") {
    return false;
  }

  if (!isValidateEmail(user.email)) {
    return false;
  }

  if (user.phone === null || user.phone === "") {
    return false;
  }

  if (user.address === null || user.address === "") {
    return false;
  }

  if (user.idService === null || user.idService === "") {
    return false;
  }

  if (user.idTicketType === null || user.idTicketType === "") {
    return false;
  }

  if (user.idTicketArea === null || user.idTicketArea === "") {
    return false;
  }

  if (user.ticketDescription === null || user.ticketDescription === "") {
    return false;
  }

  return true;
};

const showError = (user, setFocus, setError, setShowPersonalData, language) => {
  if (user.name === null || user.name === "") {
    setFocus(1);
    setError(language.alert_user_popup_name);
    setShowPersonalData(true);
    return;
  }

  if (user.lastname === null || user.lastname === "") {
    setFocus(2);
    setError(language.alert_user_popup_lastname);
    setShowPersonalData(true);
    return;
  }

  if (!isValidateEmail(user.email)) {
    setFocus(3);
    setError(language.alert_user_popup_email);
    setShowPersonalData(true);
    return;
  }

  if (user.phone === null || user.phone === "") {
    setFocus(4);
    setError(language.alert_user_popup_phone);
    setShowPersonalData(true);
    return;
  }

  if (user.address === null || user.address === "") {
    setFocus(5);
    setError(language.alert_user_popup_address);
    setShowPersonalData(true);
    return;
  }

  if (user.idService === null || user.idService === "") {
    setFocus(9);
    setError(language.alert_support_popup_ticket_service);
    return;
  }

  if (user.idTicketType === null || user.idTicketType === "") {
    setFocus(6);
    setError(language.alert_support_popup_type_ticket);
    return;
  }

  if (user.idTicketArea === null || user.idTicketArea === "") {
    setFocus(7);
    setError(language.alert_support_popup_area_ticket);
    return;
  }

  if (user.ticketDescription === null || user.ticketDescription === "") {
    setFocus(8);
    setError(language.alert_support_popup_ticket_description);
    return;
  }

  return setFocus(0);
};

const Support = (props) => {

  const ticket_types = [
    {
      value: "",
      label: props.language.support_select_placeholder,
    },
    {
      value: "Peticiones",
      label: props.language.support_select_option_customer_requests,
    },
    {
      value: "Reclamos",
      label: props.language.support_select_option_customer_claims,
    },
    {
      value: "Quejas",
      label: props.language.support_select_option_customer_complaints,
    },
    {
      value: "Sugerencias",
      label: props.language.support_select_option_customer_suggestions,
    },
  ];

  const ticket_area = [
    {
      value: "",
      label: props.language.support_select_placeholder,
    },
    {
      value: "Facturación",
      label: props.language.support_select_option_billing,
    },
    {
      value: "Operaciones",
      label: props.language.support_select_option_operations,
    },
    {
      value: "Tech",
      label: props.language.support_select_option_tech,
    },
    {
      value: "Contabilidad",
      label: props.language.support_select_option_accounting,
    },
    {
      value: "Legal",
      label: props.language.support_select_option_legal,
    },
    {
      value: "Customer Success",
      label: props.language.support_select_option_customer_success,
    },
  ];

  const initTicket = {
    idUser: props.user.idUser,
    idService: "",
    idDocument: props.user.idDocument,
    name: props.user.name,
    lastname: props.user.lastname,
    email: props.user.email,
    phone: props.user.phone,
    address: props.user.address,
    ticketDescription: "",
    idTicketType: "",
    idTicketArea: "",
    file: "",
  };

  const [user, setUser] = useState(initTicket);
  const [isEnable, setEnable] = useState(false);
  const [personalData, setShowPersonalData] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(0);
  const [error, setError] = useState("");

  let language = props.language;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEnable) {
      setLoading(true);
      const formData = new FormData();

      formData.append("idUser", user.idUser);
      formData.append("idDocument", user.idDocument);
      formData.append("idService", user.idService);
      formData.append("serviceName", props.totalServices.find(service => service.idService === parseInt(user.idService)).name);
      formData.append("name", user.name);
      formData.append("lastname", user.lastname);
      formData.append("email", user.email);
      formData.append("phone", user.phone);
      formData.append("address", user.address);
      formData.append("ticketDescription", user.ticketDescription);
      formData.append("idTicketType", user.idTicketType);
      formData.append("idTicketArea", user.idTicketArea);
      formData.append("channel", "Platforms");
      if (file) {
        formData.append("file", file.files[0]);
      } else {
        formData.append("file", null);
      }

      try {
        axios.post(`${process.env.REACT_APP_NEU_ENERGY_SUPPORT_SIGNIN}support`, formData)
          .then(({ data }) => {
            if (data.message === "Ticket created") {
              props.setShowSupport(false)
              Alert.success(props.language.alert_support_popup_success, 7000);
            } else {
              Alert.error(props.language.alert_submit_form_popup_failed, 6000);
            }
            setLoading(false);
            setFile(null);
            setUser({ ...user, ticketDescription: "" });
          });
      } catch (error) {
        console.log(error);
        Alert.error(props.language.alert_submit_form_popup_failed, 6000);
        setLoading(false);
      }
    } else {
      showError(user, setFocus, setError, setShowPersonalData, language);
    }
  };

  useEffect(() => {
    setEnable(isValidUser(user));
  }, [setEnable, user]);

  useEffect(() => {
    if (props.totalServices.length === 1) {
      setUser((prevUser) => ({ ...prevUser, idService: props.totalServices[0].idService }));
    } else {
      setUser((prevUser) => ({ ...prevUser, idService: "" }));
    }
  }, [props.totalServices]);

  useEffect(() => {
    if (focus !== 0) { showError(user, setFocus, setError, setShowPersonalData, language) }
  }, [user, language, focus]);

  const optionsData = [{ name: props.language.support_select_placeholder, idService: "" }, ...props.totalServices]

  return (
    <Drawer
      show={props.showSupport}
      size={"xs"}
      placement={"right"}
      backdropClassName={"bg-black opacity-80 pe-pointer"}
      onHide={() => props.setShowSupport(false)}
    >
      <form className="bg-gray-500 px-4 overflow-auto vh-100 py-4" onSubmit={handleSubmit}>
        <div className="row-start w-100 align-items-center">
          <div className="row-center align-items-center w-100">
            <i className="fa-solid fa-headset txt-12 txt-white me-3"></i>
            <div className="txt-title txt-bold txt-12 txt-white">{props.language.support_main_title_drawer}</div>
          </div>
        </div>
        <div className="row-center w-100">
          <div className="txt-phrase txt-regular txt-8 txt-white text-wrap text-center my-3">
            {props.language.support_main_description_drawer}
          </div>
        </div>
        <div className="w-100 pb-3">
          <div className="column-start align-items-center w-100 bg-gray-800 p-3 rounded-8">
            <div
              className="row-between align-items-center w-100 pe-pointer"
              onClick={() => { setShowPersonalData(!personalData); }}
            >
              <div className="row-start align-items-center">
                <i className="fa-regular fa-user txt-white txt-12"></i>
                <div className="txt-phrase txt-semibold txt-white txt-8 ms-3 pe-pointer">{props.language.support_title_section_personal_data}</div>
              </div>
              <i
                className={`fa-regular fa-chevron-right txt-white txt-12 pe-pointer ${!personalData ? "account-detail-open" : "account-detail-close"
                  }`}
              ></i>
            </div>
            <Animation.Collapse in={personalData} dimension={"height"}>
              <div className="column-start align-items-center w-100">
                <div className="w-100 py-4">
                  <div className="w-100 my-2">
                    <Input disabled label={props.language.support_input_label_id_document} type={"text"} value={user.idDocument} />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_name}
                      type={"text"}
                      value={user.name}
                      onChange={({ target }) => {
                        setUser({ ...user, name: target.value });
                      }}
                      error={focus === 1 ? error : ""}
                    />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_lastname}
                      type={"text"}
                      value={user.lastname}
                      onChange={({ target }) => {
                        setUser({ ...user, lastname: target.value });
                      }}
                      error={focus === 2 ? error : ""}
                    />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_email}
                      type={"email"}
                      value={user.email}
                      onChange={({ target }) => {
                        setUser({ ...user, email: target.value });
                      }}
                      error={focus === 3 ? error : ""}
                    />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_phone}
                      type={"tel"}
                      value={user.phone}
                      onChange={({ target }) => {
                        setUser({ ...user, phone: target.value });
                      }}
                      error={focus === 4 ? error : ""}
                    />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_address}
                      type={"text"}
                      value={user.address}
                      onChange={({ target }) => {
                        setUser({ ...user, address: target.value });
                      }}
                      error={focus === 5 ? error : ""}
                    />
                  </div>
                </div>
              </div>
            </Animation.Collapse>
          </div>
        </div>
        <div className="w-100">
          <div className="column-start align-items-start w-100">
            <div className="form-floating w-100">
              <select className={`form-select border-2 border-color-${focus === 9 ? 'red-500' : 'white'} txt-white txt-phrase txt-8 bg-gray-800`} id="floatingSelect" aria-label="Floating label select" onChange={({ target }) => {
                setUser({ ...user, idService: target.value });
              }} >
                {props.totalServices.length === 1 ? <option key={props.totalServices[0].idService} defaultValue={props.totalServices[0].idService}>{props.totalServices[0].name}</option>
                  : (optionsData.map(option => <option key={option.idService} value={option.idService} onChange={({ target }) => { setUser({ ...user, idService: target.value }) }}>{option.name}</option>))}
              </select>
              <label htmlFor="floatingSelect" className="txt-white txt-phrase">{props.language.support_select_label_service}</label>
            </div>
            {focus === 9 && <p className="txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap">{error}</p>}
          </div>
        </div>
        <div className="w-100 mt-3">
          <Select
            label={props.language.support_select_label_type_ticket}
            background={"gray-800"}
            value={user.idTicketType}
            options={ticket_types}
            onChange={({ target }) => {
              setUser({ ...user, idTicketType: target.value });
            }}
            error={focus === 6 ? error : ""}
          />
        </div>
        <div className="w-100 my-3">
          <Select
            label={props.language.support_select_label_area_ticket}
            background={"gray-800"}
            value={user.idTicketArea}
            options={ticket_area}
            onChange={({ target }) => {
              setUser({ ...user, idTicketArea: target.value });
            }}
            error={focus === 7 ? error : ""}
          />
        </div>
        <div className="txt-phrase txt-semibold txt-white txt-8 w-100">{props.language.support_title_section_detail_ticket}</div>
        <textarea
          placeholder={`${props.language.support_input_label_request_ticket}`}
          className={`text-area ps-3 pt-1 my-2 border-2 border-color-${focus === 8 ? 'red-500' : 'white'}`}
          onChange={({ target }) => setUser({ ...user, ticketDescription: target.value })}
          value={user.ticketDescription}
        />
        {focus === 8 ? <p className='txt-phrase txt-regular txt-6 txt-red-500 mb-2 w-100 text-wrap'>{error}</p> : null}
        <div className="txt-phrase txt-semibold txt-white txt-8 w-100">{props.language.support_title_section_upload_file}</div>
        <div className="txt-phrase txt-regular txt-neutral-50 txt-8 my-2 text-wrap">
          {props.language.support_description_section_upload_file}
        </div>
        <div className="row-start align-items-center">
          <input
            type="file"
            id="pqrFile"
            name="pqrFile"
            accept="image/*, .pdf, .doc, .docx"
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.target);
            }}
          />
          <label htmlFor="pqrFile" className="py-2 px-4 rounded-8 border-1 border-color-green-500 pe-pointer w-100">
            <div className="txt-phrase txt-medium txt-green-500 txt-8 pe-pointer"> <i className="fa-light fa-upload txt-10 txt-green-500 me-3 pe-pointer" />{props.language.support_button_label_upload_file}</div>
          </label>
        </div>
        {file && (
          <p className="text-white txt-regular text-wrap txt-8 mt-2">
            {file.value.slice(file.value.lastIndexOf("\\") + 1)}
            <i
              className="fa-solid fa-close pe-pointer ms-2 txt-red text-wrap-500 txt-10"
              onClick={() => {
                setFile(null);
              }}
            />
          </p>
        )}
        <button
          type="submit"
          className="row-center align-items-center bg-green-500 rounded-8 py-2 px-3 mt-4 pe-pointer w-100 txt-phrase txt-semibold txt-8 txt-neutral-800"
        >
          {loading ? <Loader size="sm" /> : props.language.support_button_label_send_form}
        </button>
      </form>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  language: state.shared.language,
  totalServices: state.services.totalServices,
});

export default connect(mapStateToProps)(Support);
