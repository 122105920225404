import React from 'react'

import { Calendar as Datepicker } from 'react-calendar';
import { Transition } from 'react-transition-group';
import { calendarIntervals, setCalendarDates } from '../../../services/CalendarHandler';

const Calendar = (props) => {

    let show = props.show;
    let interval = props.interval;
    let start = props.start;
    let end = props.end;
    let setDates = props.setDates;

    return (
        <Transition
            in={show}
            timeout={50}>
            {state => (
                <div className={`position-absolute column-start align-items-center top-100 zindex-6 trans-fade trans-fade-${state}`} style={{ width: 300 }}>
                    <div className="calendar-arrow"></div>
                    <Datepicker
                        next2Label={null}
                        prev2Label={null}
                        value={interval === 1 ? [start, end] : start}
                        minDetail={calendarIntervals[interval]}
                        maxDetail={calendarIntervals[interval]}
                        maxDate={new Date()}
                        className={"bg-gray-500 txt-phrase txt-8 p-2 rounded-8"}
                        nextLabel={<i className="fa-regular fa-chevron-right txt-8 txt-white pe-pointer"></i>}
                        prevLabel={<i className="fa-regular fa-chevron-left txt-8 txt-white pe-pointer"></i>}
                        onChange={(value) => {setDates(setCalendarDates(value, interval)); console.log('value', value, 'interval', interval)} } />
                </div>)}
        </Transition>
    )
}


export default Calendar